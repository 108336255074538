import ProductSubGroupEdit from './ProductSubGroupEdit';
import ProductSubGroupCreate from './ProductSubGroupCreate';
import hasPermission from '../shared/hasPermission';

export default permissions => ({
  create: hasPermission(permissions, { create: ['/api/web/product_subgroups'] })
    ? ProductSubGroupCreate : null,
  edit: hasPermission(permissions, { update: ['/api/web/product_subgroups/'] })
    ? ProductSubGroupEdit : null,
});
