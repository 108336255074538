import ProductEdit from './ProductEdit';
import ProductCreate from './ProductCreate';
import hasPermission from '../shared/hasPermission';

export default permissions => ({
  create: hasPermission(permissions, { create: ['/api/web/products'] })
    ? ProductCreate : null,
  edit: hasPermission(permissions, { update: ['/api/web/products/'] })
    ? ProductEdit : null,
});
