import * as React from 'react';
import { useState } from 'react';
import {
  Datagrid,
  DateInput,
  TextField,
  SelectInput,
  Filter,
  FormDataConsumer,
  SearchInput,
  SimpleShowLayout,
  usePermissions,
  useAuthProvider,
} from 'react-admin';

import List from '../shared/SimappList';
import { makeStyles } from '@material-ui/core/styles';
import { Typography } from '@material-ui/core';

const modules = [
  { id: "", name: " "},
  { id: "Webapp Authentication", name: "Webapp Authentication"},
  { id: "Backend Authentication", name: "Backend Authentication"},
  { id: "Groups", name: "Groups"},
  { id: "Webapp Users", name: "Webapp Users"},
  { id: "Backend Users", name: "Backend Users"},
  { id: "Credentials", name: "Credentials"},
  { id: "License", name: "License"},
]

const types = [
  { id: "", name: " "},
  { id: "Creation", name: "Creation"},
  { id: "Update", name: "Update"},
  { id: "Deletion", name: "Deletion"},
  { id: "Activation", name: "Activation"},
  { id: "Deactivation", name: "Deactivation"},
  { id: "Downloaded", name: "Downloaded"},
  { id: "Mail", name: "Mail"},
]

const useStyles = makeStyles(theme => ({
  error: {
      marginBottom: '1rem',
      color: theme.palette.error.main
  },
  wrapper: {
    display: 'flex',
    flexWrap: 'wrap',
    alignItems: 'flex-end',
    '& > div': {
      marginRight: '1rem'
    }
  }
}));

const AudiTrailFilter = props => {
  const classes = useStyles();
  const [showDateError, setShowDateError] = useState(false);

  return (
    <div>
      <Filter {...props}>
      <FormDataConsumer form={'filterForm'} alwaysOn source='stateId'>
        {({ formData, dispatch, ...rest }) => {
          setShowDateError(formData.start_time && formData.end_time && formData.end_time < formData.start_time)
          return (
            <div className={classes.wrapper}>
              <SearchInput
                source="q"
                alwaysOn
                inputProps={{
                  maxLength: 200,
                }}
              />
              <SelectInput
                source="module"
                choices={modules}
                label="Module"
                alwaysOn
              />
              <SelectInput
                source="type"
                choices={types}
                label="Modification Type"
                alwaysOn
              />
              <DateInput
                source="start_time"
                label="Start Time"
                alwaysOn
              />
              <DateInput
                source="end_time"
                label="End Time"
                alwaysOn
              />
            </div>
          )}}
        </FormDataConsumer>
      </Filter>
      { showDateError && (
        <Typography className={classes.error}>Start date cannot be after end date.</Typography>
      )}
    </div>
  );
};


const AuditTrailDetails = props => (
  <SimpleShowLayout record={props.record}>
    <TextField source="email" />
    <TextField source="title" />
    <TextField source="details" component="pre" />
  </SimpleShowLayout>
);

const AudiTrailList = props => {
  const { permissions } = usePermissions();
  const authProvider = useAuthProvider();

  return (
    <List
      {...props}
      title="Audit Trail"
      filters={<AudiTrailFilter />}
      actions={<React.Fragment />}
      sort={{ order: 'DESC', field: 'id' }}
    >
      <Datagrid
        isRowSelectable={record => false
        }
        expand={AuditTrailDetails}
      >
        <TextField source="id" label="ID" />
        <TextField source="name" label="Name" />
        <TextField source="module" label="Module" />
        <TextField source="modification_type" label="Modification Type" />
        <TextField source="created_at" label="Timestamp" />
      </Datagrid>
    </List>
  );
};

export default AudiTrailList;
