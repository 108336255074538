import ContentTemplateIcon from '@material-ui/icons/FileCopy';
import ContentTemplateCreate from './ContentTemplateCreate';
import ContentTemplateEdit from './ContentTemplateEdit';
import ContentTemplateList from './ContentTemplateList';
import hasPermission from '../shared/hasPermission';

export default permissions => ({
  options: { label: 'Templates' },
  list: hasPermission(permissions, { create: ['/api/web/content_templates'] })
    ? ContentTemplateList : null,
  create: hasPermission(permissions, { create: ['/api/web/content_templates'] })
    ? ContentTemplateCreate : null,
  edit: hasPermission(permissions, { update: ['/api/web/content_templates/'] })
    ? ContentTemplateEdit : null,
  icon: ContentTemplateIcon,
});
