import {
  get,
} from 'lodash';
import isPublished from './isPublished';

function hasUnpublishedChanges(record, opts = {
  tolerance: 5000, // Tolerance in ms
}) {
  const releasedAt = get(record, 'released_at');
  const updatedAt = get(record, 'updated_at');

  if (!isPublished(record)) {
    return false;
  }

  const releasedAtTs = (new Date(releasedAt)).getTime();
  const updatedAtTs = (new Date(updatedAt)).getTime();

  return (updatedAtTs - opts.tolerance) > releasedAtTs;
}

export default hasUnpublishedChanges;
