import * as React from 'react';
import { cloneElement } from 'react';
import {
  Datagrid,
  FunctionField,
  BooleanInput,
  Filter,
  SearchInput,
  EditButton,
  DeleteButton,
  useListContext,
  usePermissions,
  useAuthProvider,
  TopToolbar,
  CreateButton,
  sanitizeListRestProps,
} from 'react-admin';

import { get } from 'lodash';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import { makeStyles } from '@material-ui/core';
import hasPermission from '../shared/hasPermission';
import { sanitizeButtonProps } from '../shared/sanitizeReactProps';
import StatusTextField from '../shared/StatusTextField';
import StatusBooleanField from '../shared/StatusBooleanField';
import List from '../shared/SimappList';

const UserGroupActions = props => {
  const {
    className,
    exporter,
    filters,
    maxResults,
    ...rest
  } = props;
  const {
    currentSort,
    resource,
    displayedFilters,
    filterValues,
    hasCreate,
    basePath,
    selectedIds,
    showFilter,
    total,
  } = useListContext();
  const { permissions } = usePermissions();

  return hasPermission(permissions, { create: '/api/web/user_groups' }) ? (
    <TopToolbar
      className={className}
      {...sanitizeListRestProps(rest)}
    >
      {filters && cloneElement(filters, {
        resource,
        showFilter,
        displayedFilters,
        filterValues,
        context: 'button',
      })}
      <CreateButton basePath={basePath} />
    </TopToolbar>
  ) : null;
};

const UserDeleteButton = props => (
  <DeleteButton
    {...sanitizeButtonProps(props)}
    title="Delete"
    label={null}
    undoable={false}
    confirmTitle={`Delete group "${props.record.name}"`}
    confirmContent="Are you sure to delete the group? The group will be deactivated and the action cannot be undone."
  />
);

const useQuickFilterStyles = makeStyles(theme => ({
  boolean: {
      marginBottom: theme.spacing(0.5),
  },
  centerBoolean: {
    display: 'block',
    textAlign: 'center',
  },
  centerBooleanTh: {
    textAlign: 'center',
  }
}));

const UserGroupFilter = props => {
  const classes = useQuickFilterStyles();

  return (
    <Filter {...props}>
      <SearchInput
        source="q"
        alwaysOn
        inputProps={{
          maxLength: 200,
        }}
      />
      <BooleanInput className={classes.boolean} label="Show deactivated groups" source="deleted" alwaysOn />
    </Filter>
  );
};

const UserGroupList = props => {
  const { permissions } = usePermissions();
  const authProvider = useAuthProvider();
  const classes = useQuickFilterStyles();
  return (
    <List
      {...props}
      filters={<UserGroupFilter />}
      actions={<UserGroupActions />}
      sort={{ order: 'DESC', field: 'id' }}
    >

      <Datagrid
        isRowSelectable={record => {
          const session = authProvider.getSession();
          return !get(session, 'user.id', null) === record.id;
        }}
      >
        <StatusTextField source="name" label="Company Name" />
        <StatusTextField source="contact_name" label="Contact Name" />
        <StatusTextField source="contact_email" label="Contact Email" />
        <StatusTextField source="parent_name" label="Main Group" />
        <StatusBooleanField source="permissions.credentials" label="Credentials" looseValue={true} className={classes.centerBoolean} headerClassName={classes.centerBooleanTh} sortable={false} />
        <StatusBooleanField source="permissions.license" label="License" looseValue={true} className={classes.centerBoolean} headerClassName={classes.centerBooleanTh} sortable={false} />
        <StatusBooleanField source="permissions.dev_mode" label="Dev Mode" looseValue={true} className={classes.centerBoolean} headerClassName={classes.centerBooleanTh} sortable={false} />
        <FunctionField
          label="Actions"
          textAlign="right"
          render={record => (
            <ButtonGroup>
              {hasPermission(permissions, { update: '/api/web/user_groups/' }) && record.deleted_at === null
            && (
              <EditButton
                {...sanitizeButtonProps(props)}
                title="Edit"
                label={null}
                record={record}
              />
            )}
              {hasPermission(permissions, { delete: '/api/web/user_groups/' }) && record.deleted_at === null
            && (
              <UserDeleteButton
                {...sanitizeButtonProps(props)}
                record={record}
              />
            )}
            </ButtonGroup>
          )}
        />
      </Datagrid>
    </List>
  );
};

export default UserGroupList;
