import * as React from 'react';
import {
  TextInput,
  ReferenceInput,
  AutocompleteInput,
  required,
} from 'react-admin';

const ProductSubGroupForm = props => (
  <React.Fragment>
    <TextInput
      source="name"
      label="Name"
      validate={required()}
      fullWidth
    />
    <ReferenceInput
      label="Product Group"
      source="product_group_id"
      reference="product_groups"
      sort={{ field: 'name', order: 'ASC' }}
      perPage={1000}
      fullWidth
      allowEmpty={false}
      validate={required()}
    >
      <AutocompleteInput
        optionText="name"
      />
    </ReferenceInput>
  </React.Fragment>
);

export default ProductSubGroupForm;
