import * as React from 'react';
import PropTypes from 'prop-types';
import {
  ImageField,
} from 'react-admin';
import {
  set,
  get,
} from 'lodash';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
  image: {
    '& img': {
      maxHeight: 120,
      margin: 0
    }
  }
});

const RelativeImageField = props => {
  const classes = useStyles();
  const record = {};
  set(record, props.source,
    props.basePath + get(props.record, props.source, ''));
  set(record, props.title,
    get(props.record, props.title, ''));

  return get(props.record, props.source) ? (
    <ImageField
      {...props}
      record={record}
      className={classes.image}
    />
  ) : null;
};

RelativeImageField.propTypes = {
  basePath: PropTypes.string.isRequired,
};

export default RelativeImageField;
