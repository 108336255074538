import * as React from 'react';
import {
  TextInput,
  NumberInput,
  BooleanInput,
  ReferenceArrayInput,
  FormDataConsumer,
  required,
  minValue,
  regex,
} from 'react-admin';
import { useForm } from 'react-final-form';
import CollapsibleArrayInput from '../shared/CollapsibleArrayInput';

const isInteger = regex(/[0-9]+/, 'Invalid number.');
const gtZero = minValue(0, 'Value must be greater then 0.');

const ConsumablesCalculatorAssayForm = props => (
  <React.Fragment>
    <TextInput
      source="title"
      label="Product Name"
      validate={required()}
      fullWidth
    />
    <TextInput
      source="ref"
      label="Reference Number"
      validate={required()}
      fullWidth
    />
    <NumberInput
      source="tests_per_kit"
      label="Test per Kit"
      step={1}
      min={1}
      validate={[required(), gtZero]}
      fullWidth
    />
    <NumberInput
      source="on_board_stability"
      label="On Board Stability (days)"
      step={1}
      min={1}
      validate={[required(), gtZero]}
      fullWidth
    />
    <NumberInput
      source="volume_sol1_rec_xl"
      label="Volume SOL 1 for reconstitution XL (µL)"
      step={1}
      min={0}
      fullWidth
      validate={isInteger}
    />
    <NumberInput
      source="volume_sol2_rec_xl"
      label="Volume SOL 2 for reconstitution XL (µL)"
      step={1}
      min={0}
      fullWidth
      validate={isInteger}
    />
    <NumberInput
      source="volume_sol1_rec_k"
      label="Volume SOL 1 for reconstitution K (µL)"
      step={1}
      min={0}
      fullWidth
      validate={isInteger}
    />
    <NumberInput
      source="volume_sol2_rec_k"
      label="Volume SOL 2 for reconstitution K (µL)"
      step={1}
      min={0}
      fullWidth
      validate={isInteger}
    />
    <NumberInput
      source="calibrations_per_cal_kit"
      label="Calibrations per CAL KIT"
      step={1}
      min={1}
      validate={[required(), gtZero]}
      fullWidth
    />
    <NumberInput
      source="test_per_cal"
      label="Test per CAL"
      step={1}
      min={1}
      validate={[required(), gtZero]}
      fullWidth
    />
    <NumberInput
      source="calibration_stability"
      label="Calibration Stability (days)"
      step={1}
      min={1}
      validate={[required(), gtZero]}
      fullWidth
    />
    <NumberInput
      source="qc_levels"
      label="QC levels"
      step={1}
      min={1}
      validate={[required(), gtZero]}
      fullWidth
    />
    <NumberInput
      source="ref_cal"
      label="REF CAL"
      step={1}
      min={0}
      fullWidth
      validate={isInteger}
    />
    <TextInput
      source="ref_qc"
      label="REF QC"
      fullWidth
      validate={isInteger}
    />
    <NumberInput
      source="buffer_priming_per_test"
      label="Buffer Priming Per Test (ml)"
      step={1}
      min={1}
      validate={[required(), gtZero]}
      fullWidth
    />
    <BooleanInput
      source="recovery_test_enabled"
      label="Recovery Test Enabled"
    />
    <ReferenceArrayInput
      resource={props.resource}
      source="multi_control_ids"
      reference="consumables_calculator_multi_controls"
      label="Multi Controls"
      perPage={1000}
      fullWidth
    >
      <FormDataConsumer>
        {formDataProps => (
          <CollapsibleArrayInput
            {...formDataProps}
            source="multi_control_ids"
            optionText="name"
            fullWidth
          />
        )}
      </FormDataConsumer>
    </ReferenceArrayInput>
  </React.Fragment>
);

export default ConsumablesCalculatorAssayForm;
