import React, { useState } from 'react';
import {
  TextInput,
  ReferenceArrayInput,
  ReferenceInput,
  BooleanInput,
  AutocompleteInput,
  FormDataConsumer,
  AutocompleteArrayInput,
  required,
} from 'react-admin';
import { useForm } from 'react-final-form';
import GroupedArrayInput from '../shared/GroupedArrayInput';
import KeywordsInput from '../shared/KeywordsInput';
import FileUploadInput from '../shared/FileUploadInput';
import dataProvider from '../../dataProvider';

const ClassicalContentForm = props => {
  const [lastClassicalContentTypeId, setLastClassicalContentTypeId] = useState();
  const [allowedInMaterials, setAllowedInMaterials] = useState(true);
  const [allowedInSoftware, setAllowedInSoftware] = useState(true);
  const form = useForm();

  // useEffect() is only called for the first selection, so we have to use FormDataProvider as workaround
  const updateAllowedSettings = () => {
    const classicalContentTypeId = form.getState().values.classical_content_type_id;
    if (typeof classicalContentTypeId !== 'undefined' && classicalContentTypeId !== lastClassicalContentTypeId) {
      dataProvider
        .getOne('classical_content_types', { id: classicalContentTypeId })
        .then(response => {
          setLastClassicalContentTypeId(classicalContentTypeId);
          setAllowedInMaterials(response.data.available_in_materials);
          setAllowedInSoftware(response.data.available_in_software);
          if (!response.data.available_in_materials) {
            form.change('available_in_materials', false);
            if (response.data.available_in_software) form.change('available_in_software', true);
          }
          if (!response.data.available_in_software) {
            form.change('available_in_software', false);
            if (response.data.available_in_materials) form.change('available_in_materials', true);
          }
        });
    }
  };

  return (
    <React.Fragment>
      <FormDataConsumer>
        {formDataProps => {
          updateAllowedSettings();
        }}
      </FormDataConsumer>

      <FileUploadInput
        source="fileInfo"
        labelSingle="Upload Classical Content File"
        validate={required()}
      />
      <ReferenceInput
        label="Content Type"
        source="classical_content_type_id"
        reference="classical_content_types"
        perPage={1000}
        fullWidth
        allowEmpty={false}
        validate={required()}
      >
        <AutocompleteInput
          optionText="title"
        />
      </ReferenceInput>
      <ReferenceArrayInput
        resource={props.resource}
        source="product_ids"
        reference="products_tree"
        sort={{ field: 'name', order: 'ASC' }}
        label="Products"
        perPage={1000}
        fullWidth
      >
        <FormDataConsumer>
          {formDataProps => (
            <GroupedArrayInput
              {...formDataProps}
              source="product_ids"
              groupSource="products"
              fullWidth
            />
          )}
        </FormDataConsumer>
      </ReferenceArrayInput>
      <TextInput
        source="title"
        validate={required()}
        fullWidth
      />
      <TextInput
        source="mastercontrol_id"
        label="Master Control ID"
        helperText="Reference ID from mastercontrol"
        validate={required()}
        fullWidth
      />
      <ReferenceArrayInput
        resource={props.resource}
        source="keyword_ids"
        reference="scientific_literature_keywords"
        label="Keywords"
        sort={{ field: 'name', order: 'ASC' }}
        perPage={25}
        fullWidth
      >
        <KeywordsInput
          optionText="name"
          translateChoice={false}
          fullWidth
        />
      </ReferenceArrayInput>
      <ReferenceInput
        source="language_id"
        reference="languages"
        sort={{ field: 'name', order: 'ASC' }}
        label="Language"
        perPage={1000}
        allowEmpty={false}
        validate={required()}
        fullWidth
      >
        <AutocompleteInput
          optionText="name"
          fullWidth
        />
      </ReferenceInput>
      <ReferenceArrayInput
        resource={props.resource}
        source="country_ids"
        reference="countries"
        sort={{ field: 'name', order: 'ASC' }}
        label="Available in country"
        validate={required()}
        perPage={1000}
        fullWidth
      >
        <FormDataConsumer>
          {formDataProps => (
            <GroupedArrayInput
              {...formDataProps}
              source="country_ids"
              groupSource="countries"
              fullWidth
            />
          )}
        </FormDataConsumer>
      </ReferenceArrayInput>
      <TextInput
        source="description"
        label="Description"
        fullWidth
      />
      <TextInput
        source="usage"
        label="Usage"
        fullWidth
      />
      <TextInput
        source="comment"
        label="Internal Comment"
        fullWidth
        multiline
      />
      <BooleanInput
        source="active"
        label="Make this content Active"
      />
      <BooleanInput
        source="shareable"
        label="Make this content Share Enabled"
      />
      <BooleanInput
        source="available_for_distributors"
        label="Make this content available for Distributors"
      />
      <BooleanInput
        source="available_in_materials"
        label="Make this content available in Materials"
        initialValue
        disabled={!allowedInMaterials}
      />
      <BooleanInput
        source="available_in_software"
        label="Make this content available in Software Download"
        disabled={!allowedInSoftware}
      />
      <ReferenceArrayInput
        resource={props.resource}
        source="recommended_country_ids"
        reference="countries"
        sort={{ field: 'name', order: 'ASC' }}
        label="Recommended for country"
        perPage={1000}
        fullWidth
      >
        <FormDataConsumer>
          {formDataProps => (
            <GroupedArrayInput
              {...formDataProps}
              source="recommended_country_ids"
              groupSource="countries"
              fullWidth
            />
          )}
        </FormDataConsumer>
      </ReferenceArrayInput>
    </React.Fragment>
  );
};

export default ClassicalContentForm;
