import * as React from 'react';
import { useState } from 'react';
import {
  get,
  includes,
  property,
  uniq,
  intersection,
  isFunction,
} from 'lodash';
import {
  CheckboxGroupInput,
  InputHelperText,
  useInput,
} from 'react-admin';
import { useForm, Field } from 'react-final-form';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Box from '@material-ui/core/Box';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
  outerStyles: {
    marginTop: 8,
    marginBottom: 28 // 4 + <p> as used in MuiTextField
  },
  groupToggleCheckbox: {
    marginLeft: 10,
    '& .MuiFormControlLabel-label': {
      fontSize: '0.75rem'
    }
  }
});

const CollapsibleArrayInput = props => {
  const classes = useStyles();
  const { id, input, meta: { error, touched } } = useInput(props);
  const { isRequired } = props;
  const selectedIds = props.formData[props.source] || [];
  const totalNumberChoices = (props.choices || []).length;
  const [state, setState] = useState({
    mainIsOpen: false,
  });

  function renderChoices() {
    return (
      <Box>
        <CheckboxGroupInput
          {...props}
          choices={props.choices}
        />
      </Box>
    );
  }

  function renderField() {
    const choiceIds = (props.choices || []).map(property('id'));
    const allChecked = selectedIds.length === props.choices.length && props.choices.length > 0;

    return (
      <React.Fragment>
        <details
          id={id}
          open={state.mainIsOpen}
          className={`${classes.outerStyles} MuiFormLabel-root ${touched && error ? ' Mui-error' : ''}`}
          onToggle={event => {
            setState({
              mainIsOpen: event.target.open,
            });
          }}
        >
          <summary>
            {props.label + (isRequired ? '*' : '')} ({selectedIds.length}/{totalNumberChoices})
            <FormControlLabel
              className={classes.groupToggleCheckbox}
              control={(
                <Checkbox
                  checked={allChecked}
                  onClick={() => {
                    let newIds = [];

                    if (allChecked) {
                      newIds = selectedIds.filter(id => !includes(choiceIds, id));
                    } else {
                      newIds = uniq(
                        selectedIds.concat(choiceIds)
                      );
                    }

                    input.onChange(newIds);
                  }}
                />
              )}
              label={`${allChecked ? 'Deselect' : 'Select'} all`}
            />
          </summary>
          { state.mainIsOpen ? renderChoices() : null }
        </details>
        {touched && error && (
          <p className={`MuiFormHelperText-root MuiFormHelperText-marginDense${touched && error ? ' Mui-error' : ''}`}>
            <InputHelperText
              touched={touched}
              error={error}
              helperText={props.helperText}
            />
            <br />
          </p>
        )}
      </React.Fragment>
    );
  }

  return (
    <Field
      name={props.source}
      render={renderField}
    />
  );
};

export default CollapsibleArrayInput;
