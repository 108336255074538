import * as React from 'react';
import {
  TextInput,
  required,
} from 'react-admin';
import FileUploadInput from '../shared/FileUploadInput';

const API_URL = process.env.REACT_APP_API_URL;

const ContentTemplateForm = props => (
  <React.Fragment>
    <TextInput
      source="name"
      label="Template Name"
      validate={required()}
      fullWidth
    />
    <FileUploadInput
      source="fileInfo"
      labelSingle="simapp.action.uploadTemplateFile"
      uploadUrl={`${API_URL}/upload/template`}
      validate={required()}
    />
  </React.Fragment>
);

export default ContentTemplateForm;
