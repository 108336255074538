import * as React from 'react';
import { useState } from 'react';
import {
  get,
  includes,
  property,
  uniq,
  intersection,
  isFunction,
} from 'lodash';
import {
  CheckboxGroupInput,
  InputHelperText,
  useInput,
} from 'react-admin';
import { Field } from 'react-final-form';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Box from '@material-ui/core/Box';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
  outerStyles: {
    marginTop: 8,
    marginBottom: 4
  },
  groupTitle: {
    cursor: 'pointer',
    outline: 'none' // hide default Chrome outline when focused
  },
  groupToggleCheckbox: {
    marginLeft: 10,
    '& .MuiFormControlLabel-label': {
      fontSize: '0.75rem'
    }
  },
  innerCheckboxGroup: {
    margin: '0 10px',
    '& legend': {
      display: 'none'
    },
    '& p': {
      display: 'none'
    }
  }
});

const GroupedArrayInput = props => {
  const classes = useStyles();
  const { id, input, meta: { error, touched } } = useInput(props);
  const { isRequired } = props;
  const selectedIds = props.formData[props.source] || [];
  const sanitizedChoices = (props.choices || []);

  const totalNumberChoices = sanitizedChoices.reduce((total, group) => total + get(group, props.groupSource, []).length, 0);
  const [state, setState] = useState({
    mainIsOpen: false,
    groupIsOpen: {},
  });

  function renderField() {
    const topLevelAllChecked = (selectedIds.length === totalNumberChoices);
    return (
      <div className={classes.outerStyles}>
        <details
          id={id}
          className={` MuiFormLabel-root ${touched && error ? ' Mui-error' : ''}`}
          open={state.mainIsOpen}
          onToggle={event => {
            setState({
              mainIsOpen: event.target.open,
              groupIsOpen: state.groupIsOpen,
            });
          }}
        >
          <summary
            className={classes.groupTitle}
          >{props.label + (isRequired ? '*' : '')}
            &nbsp;({selectedIds.length}/{totalNumberChoices})
            <FormControlLabel
              className={classes.groupToggleCheckbox}
              control={(
                <Checkbox
                  checked={topLevelAllChecked}
                  onFocus={input.onFocus}
                  onBlur={input.onBlur}
                  onClick={event => {
                    const newIds = [];
                    if (!topLevelAllChecked) {
                      for (const group of sanitizedChoices) {
                        const groupElements = get(group, props.groupSource, []);
                        for (const element of groupElements) {
                          newIds.push(element.id);
                        }
                      }
                    }
                    input.onChange(newIds);
                  }}
                />
              )}
              label={`${topLevelAllChecked ? 'Deselect' : 'Select'} all`}
            />
          </summary>
          { state.mainIsOpen ? (props.choices || []).map((group, groupIndex) => {
            const choices = get(group, props.groupSource, [])
              .map(item => ({
                id: item.id,
                name: isFunction(props.optionText)
                  ? props.optionText(item)
                  : get(item, props.optionText || 'name'),
              }));

            const choiceIds = choices.map(property('id'));
            const selectedGroupIds = intersection(
              selectedIds,
              choiceIds
            );
            const allChecked = selectedGroupIds.length === choices.length && choices.length > 0;

            return (
              <Box m={1} key={group.id}>
                <details
                  open={state.groupIsOpen[groupIndex]}
                  onToggle={event => {
                    const newGroupIsOpen = state.groupIsOpen;
                    newGroupIsOpen[groupIndex] = event.target.open;
                    setState({
                      mainIsOpen: true,
                      groupIsOpen: newGroupIsOpen,
                    });

                    event.stopPropagation();
                  }}
                >
                  <summary className={classes.groupTitle}>
                    {group.name} ({selectedGroupIds.length}/{choices.length})
                    <FormControlLabel
                      className={classes.groupToggleCheckbox}
                      control={(
                        <Checkbox
                          checked={allChecked}
                          onFocus={input.onFocus}
                          onBlur={input.onBlur}
                          onClick={event => {
                            let newGroupIds = [];

                            if (allChecked) {
                              newGroupIds = selectedIds.filter(id => !includes(choiceIds, id));
                            } else {
                              newGroupIds = uniq(
                                selectedIds.concat(choiceIds)
                              );
                            }

                            input.onChange(newGroupIds);
                          }}
                        />
                      )}
                      label={`${allChecked ? 'Deselect' : 'Select'} all`}
                    />
                  </summary>
                  {/*
                    TODO: Checkboxes are utterly slow in material-ui,
                    switch to native checkboxes using custom input field instead
                  */}
                  { state.groupIsOpen[groupIndex] ? (
                    <CheckboxGroupInput
                      {...props}
                      key={group.id}
                      label={null}
                      choices={choices}
                      className={classes.innerCheckboxGroup}
                    />
                  ) : null}
                </details>
              </Box>
            );
          }) : null}
        </details>
        <p className={`MuiFormHelperText-root MuiFormHelperText-marginDense${touched && error ? ' Mui-error' : ''}`}>
          <InputHelperText
            touched={touched}
            error={error}
            helperText={props.helperText}
          />
        </p>
      </div>
    );
  }

  return (
    <Field
      name={props.source}
      render={renderField}
    />
  );
};

export default GroupedArrayInput;
