import * as React from 'react';
import {
  Edit,
  SimpleForm,
} from 'react-admin';
import ScientificLiteratureForm from './ScientificLiteratureForm';
import FormToolbar from '../shared/FormToolbar';

const ScientificLiteratureEdit = props => (
  <Edit {...props} undoable={false}>
    <SimpleForm toolbar={<FormToolbar />}>
      <ScientificLiteratureForm {...props} />
    </SimpleForm>
  </Edit>
);

export default ScientificLiteratureEdit;
