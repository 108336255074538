import * as React from 'react';
import {
  AutocompleteArrayInput,
} from 'react-admin';

const KeywordsInput = props => (
  <AutocompleteArrayInput
    {...props}
  />
);

export default KeywordsInput;
