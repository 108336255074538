import * as React from 'react';
import {
  Create,
  SimpleForm,
} from 'react-admin';
import ProductGroupForm from './ProductGroupForm';
import FormToolbar from '../shared/FormToolbar';

const ProductGroupCreate = props => (
  <Create {...props}>
    <SimpleForm
      toolbar={<FormToolbar />}
      redirect="list"
    >
      <ProductGroupForm {...props} />
    </SimpleForm>
  </Create>
);

export default ProductGroupCreate;
