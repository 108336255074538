import ClassicalContentIcon from '@material-ui/icons/Photo';
import ClassicalContentCreate from './ClassicalContentCreate';
import ClassicalContentEdit from './ClassicalContentEdit';
import ClassicalContentList from './ClassicalContentList';
import hasPermission from '../shared/hasPermission';

export default permissions => ({
  list: hasPermission(permissions, { read: ['/api/web/classical_contents'] })
    ? ClassicalContentList : null,
  create: hasPermission(permissions, { create: ['/api/web/classical_contents'] })
    ? ClassicalContentCreate : null,
  edit: hasPermission(permissions, { update: ['/api/web/classical_contents/'] })
    ? ClassicalContentEdit : null,
  icon: ClassicalContentIcon,
});
