import polyglotI18nProvider from 'ra-i18n-polyglot';
import englishMessagesRA from 'ra-language-english';

const englishMessagesSimApp = {
  simapp: {
    action: {
      uploadZip: 'Upload Zip File',
      uploadTemplateFile: 'Upload Template File'
    }
  }
};

export default polyglotI18nProvider(locale => Object.assign({}, englishMessagesRA, englishMessagesSimApp),
  'en' // Default locale
);
