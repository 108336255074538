import ContentPieceIcon from '@material-ui/icons/Extension';
import ContentPieceCreate from './ContentPieceCreate';
import ContentPieceEdit from './ContentPieceEdit';
import ContentPieceList from './ContentPieceList';
import hasPermission from '../shared/hasPermission';

export default permissions => ({
  list: hasPermission(permissions, { read: ['/api/web/content_pieces'] })
    ? ContentPieceList : null,
  create: hasPermission(permissions, { create: ['/api/web/content_pieces'] })
    ? ContentPieceCreate : null,
  edit: hasPermission(permissions, { update: ['/api/web/content_pieces/'] })
    ? ContentPieceEdit : null,
  icon: ContentPieceIcon,
});
