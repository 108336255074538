import * as React from 'react';
import { cloneElement, useMemo } from 'react';
import PropTypes from 'prop-types';
import {
  Datagrid,
  FunctionField,
  TextField,
  ReferenceField,
  BooleanField,
  Filter,
  SearchInput,
  Button,
  EditButton,
  DeleteButton,

  useListContext,
  useRefresh,
  useNotify,
  TopToolbar,
  CreateButton,
  CloneButton,
  sanitizeListRestProps,
  usePermissions,
} from 'react-admin';

import {
  partial,
} from 'lodash';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import PreviewIcon from '@material-ui/icons/Visibility';
import PublishIcon from '@material-ui/icons/Publish';
import RelativeImageField from '../shared/RelativeImageField';
import PublishDateField from '../shared/PublishDateField';
import hasPermission from '../shared/hasPermission';
import isPublished from '../shared/isPublished';
import hasUnpublishedChanges from '../shared/hasUnpublishedChanges';
import { sanitizeButtonProps } from '../shared/sanitizeReactProps';
import List from '../shared/SimappList';

const API_URL = process.env.REACT_APP_API_URL;
const BACKEND_URL = process.env.REACT_APP_BACKEND_URL;

const ScientificLiteratureActions = props => {
  const {
    className,
    exporter,
    filters,
    maxResults,
    ...rest
  } = props;
  const {
    currentSort,
    resource,
    displayedFilters,
    filterValues,
    hasCreate,
    basePath,
    selectedIds,
    showFilter,
    total,
  } = useListContext();
  const { permissions } = usePermissions();

  return hasPermission(permissions, { create: ['/api/web/scientific_literatures'] })
    ? (
      <TopToolbar
        className={className}
        {...sanitizeListRestProps(rest)}
      >
        {filters && cloneElement(filters, {
          resource,
          showFilter,
          displayedFilters,
          filterValues,
          context: 'button',
        })}
        <CreateButton basePath={basePath} />
      </TopToolbar>
    ) : null;
};

const ScientificLiteratureFilter = props => (
  <Filter {...props}>
    <SearchInput source="q" alwaysOn />
  </Filter>
);

const PreviewButton = ({ record }) => (
  <Button
    variant="outlined"
    color="primary"
    href={`${API_URL}/scientific_literature_preview/${record.id}`}
    target={`scientific-literature-preview-${record.id}`}
    title="Preview"
  >
    <PreviewIcon />
  </Button>
);

const PublishButton = props => {
  const refresh = useRefresh();
  const notify = useNotify();
  const publishScientificLiterature = () => fetch(`${API_URL}/publish/scientific_literature/${props.record.id}`, {
    credentials: 'include',
    mode: 'cors',
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json'
    }
  })
    .then(res => {
      if (res.ok) {
        refresh();
        notify('Scientific Literature published', 'success');
      } else {
        throw new Error('Invalid response.');
      }
    })
    .catch(err => {
      notify(err.toString(), 'warning');
    });
  return ((props.record.active && !isPublished(props.record)) || hasUnpublishedChanges(props.record))
    ? (
      <Button
        variant="outlined"
        color="primary"
        onClick={publishScientificLiterature}
        title="Publish"
      >
        <PublishIcon />
      </Button>
    ) : null;
};

const ShareableField = props => {
  const isShareable = (props.record.shareable_link || props.record.shareable_document);

  const record = {
    is_shareable: isShareable
  };

  return (
    <BooleanField
      {...props}
      record={record}
      source="is_shareable"
    />
  );
};

const ScientificLiteratureList = props => {
  const { permissions } = usePermissions();

  return (
    <List
      {...props}
      title="Scientific Literature"
      filters={<ScientificLiteratureFilter />}
      actions={<ScientificLiteratureActions />}
      sort={{ order: 'DESC', field: 'id' }}
    >
      <Datagrid>
        <TextField source="id" label="ID" />
        <RelativeImageField
          source="thumbnail.url"
          label="Thumbnail"
          title="fileInfo.name"
          basePath={BACKEND_URL}
        />
        <TextField source="title" />
        <TextField source="article_number" />
        <ReferenceField
          source="language_id"
          reference="languages"
          link=""
        >
          <TextField source="name" />
        </ReferenceField>
        <ShareableField label="Shareable" />
        <BooleanField label="Active" source="active" />
        <TextField source="originalFilename" label="Filename" />
        <PublishDateField
          source="released_at"
          label="Published at"
          showTime
        />
        <FunctionField
          label="Actions"
          textAlign="right"
          render={record => (
            <ButtonGroup>
              {hasPermission(permissions, { read: ['/api/web/scientific_literature_preview/'] })
            && (
              <PreviewButton
                {...sanitizeButtonProps(props)}
                record={record}
              />
            )}
              {hasPermission(permissions, { update: ['/api/web/scientific_literatures/'] })
            && (
              <EditButton
                {...sanitizeButtonProps(props)}
                title="Edit"
                label={null}
                record={record}
              />
            )}
              {hasPermission(permissions, { create: ['/api/web/scientific_literatures/'] })
            && (
              <CloneButton
                {...sanitizeButtonProps(props)}
                title="Duplicate"
                label={null}
                record={(({ fileInfo, ...rest }) => rest)(record)}
              />
            )}
              {hasPermission(permissions, { delete: ['/api/web/scientific_literatures/'] })
            && (
              <DeleteButton
                {...sanitizeButtonProps(props)}
                title="Delete"
                undoable={false}
                label={null}
                record={record}
              />
            )}
              {hasPermission(permissions, { update: ['/api/web/publish/scientific_literature/'] })
            && (
              <PublishButton
                {...sanitizeButtonProps(props)}
                record={record}
              />
            )}
            </ButtonGroup>
          )}
        />
      </Datagrid>
    </List>
  );
};

export default ScientificLiteratureList;
