import * as React from 'react';
import { cloneElement, useMemo } from 'react';
import PropTypes from 'prop-types';
import {
  Datagrid,
  FunctionField,
  TextField,
  ReferenceField,
  BooleanField,
  Filter,
  SearchInput,
  Button,
  EditButton,
  DeleteButton,

  useListContext,
  TopToolbar,
  CreateButton,
  sanitizeListRestProps,
  usePermissions,
} from 'react-admin';

import ButtonGroup from '@material-ui/core/ButtonGroup';
import PreviewIcon from '@material-ui/icons/Visibility';
import RelativeImageField from '../shared/RelativeImageField';
import PublishDateField from '../shared/PublishDateField';
import hasPermission from '../shared/hasPermission';
import { sanitizeButtonProps } from '../shared/sanitizeReactProps';
import List from '../shared/SimappList';

const API_URL = process.env.REACT_APP_API_URL;
const BACKEND_URL = process.env.REACT_APP_BACKEND_URL;

const ConsumablesCalculatorMultiControlActions = props => {
  const {
    className,
    exporter,
    filters,
    maxResults,
    ...rest
  } = props;
  const {
    currentSort,
    resource,
    displayedFilters,
    filterValues,
    hasCreate,
    basePath,
    selectedIds,
    showFilter,
    total,
  } = useListContext();
  const { permissions } = usePermissions();

  return hasPermission(permissions, { create: ['/api/web/consumables_calculator_multi_controls'] })
    ? (
      <TopToolbar
        className={className}
        {...sanitizeListRestProps(rest)}
      >
        {filters && cloneElement(filters, {
          resource,
          showFilter,
          displayedFilters,
          filterValues,
          context: 'button',
        })}
        <CreateButton basePath={basePath} />
      </TopToolbar>
    ) : null;
};

const ConsumablesCalculatorMultiControlFilter = props => (
  <Filter {...props}>
    <SearchInput source="q" alwaysOn />
  </Filter>
);

const ConsumablesCalculatorMultiControlList = props => {
  const { permissions } = usePermissions();
  return (
    <List
      {...props}
      title="CC Multi Controls"
      filters={<ConsumablesCalculatorMultiControlFilter />}
      actions={<ConsumablesCalculatorMultiControlActions />}
      sort={{ order: 'DESC', field: 'id' }}
    >
      <Datagrid>
        <TextField source="id" label="ID" />
        <TextField source="name" label="Product Name" />
        <FunctionField
          label="Actions"
          textAlign="right"
          render={record => (
            <ButtonGroup>
              {hasPermission(permissions, { update: ['/api/web/consumables_calculator_multi_controls/'] })
            && (
              <EditButton
                {...sanitizeButtonProps(props)}
                title="Edit"
                label={null}
                record={record}
              />
            )}
              {hasPermission(permissions, { delete: ['/api/web/consumables_calculator_multi_controls/'] })
            && (
              <DeleteButton
                {...sanitizeButtonProps(props)}
                title="Delete"
                undoable={false}
                label={null}
                record={record}
              />
            )}
            </ButtonGroup>
          )}
        />
      </Datagrid>
    </List>
  );
};

export default ConsumablesCalculatorMultiControlList;
