import React from 'react';
import { createSvgIcon } from '@material-ui/core';
import AuditTrailList from './AuditTrailList';
import hasPermission from '../shared/hasPermission';

const icon = createSvgIcon(React.createElement("path", {
     d: "m11.986 10.292q3.6829 0 6.884-0.57166 3.2012-0.57166 5.0504-1.6884v2.2601q0 0.91734-1.6006 1.7017-1.6006 0.78438-4.3511 1.243-2.7505 0.45866-5.9828 0.45866-3.2322 0-5.9828-0.45866-2.7505-0.45866-4.3511-1.243-1.6006-0.78438-1.6006-1.7017v-2.2601q1.8492 1.1167 5.0504 1.6884 3.2012 0.57166 6.884 0.57166zm0 10.21q3.6829 0 6.884-0.57166 3.2012-0.57166 5.0504-1.6884v2.2601q0 0.91734-1.6006 1.7017-1.6006 0.78438-4.3511 1.243-2.7505 0.45866-5.9828 0.45866-3.2322 0-5.9828-0.45866-2.7505-0.45866-4.3511-1.243-1.6006-0.78438-1.6006-1.7017v-2.2601q1.8492 1.1168 5.0504 1.6884 3.2012 0.57166 6.884 0.57166zm0-5.1051q3.6829 0 6.884-0.57166 3.2012-0.57166 5.0504-1.6884v2.2601q0 0.91734-1.6006 1.7017-1.6006 0.78438-4.3511 1.243-2.7505 0.45866-5.9828 0.45866-3.2322 0-5.9828-0.45866-2.7505-0.45866-4.3511-1.243-1.6006-0.78439-1.6006-1.7017v-2.2601q1.8492 1.1167 5.0504 1.6884 3.2012 0.57166 6.884 0.57166zm0-15.315q3.2322 0 5.9828 0.45866 2.7505 0.45866 4.3511 1.243 1.6006 0.78438 1.6006 1.7017v1.7017q0 0.91732-1.6006 1.7017-1.6006 0.78438-4.3511 1.243-2.7505 0.45866-5.9828 0.45866-3.2322 0-5.9828-0.45866-2.7505-0.45866-4.3511-1.243-1.6006-0.78438-1.6006-1.7017v-1.7017q0-0.91732 1.6006-1.7017 1.6006-0.78438 4.3511-1.243 2.7505-0.45866 5.9828-0.45866z",
  }), 'Database')

export default permissions => ({
  options: { label: 'Audit Trail' },
  list: hasPermission(permissions, { read: ['/api/web/audit_trails'] }) ? AuditTrailList : null,
  icon,
});
