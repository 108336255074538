import * as React from 'react';
import { useState } from 'react';
import { useLogin, useNotify, Notification } from 'react-admin';
import { ThemeProvider } from '@material-ui/styles';
import Container from '@material-ui/core/Container';
import Box from '@material-ui/core/Box';
import Paper from '@material-ui/core/Paper';
import CssBaseline from '@material-ui/core/CssBaseline';
import Button from '@material-ui/core/Button';
import LoginIcon from '@material-ui/icons/LockOpen';
import { makeStyles } from '@material-ui/core/styles';
import { Typography } from '@material-ui/core';

const API_URL = process.env.REACT_APP_API_URL;
const isStage = (process.env.REACT_APP_IS_STAGE === 'true') || false;

const useStyles = makeStyles({
  stage: {
    'font-style': 'italic',
    'font-weight': 'normal',
    padding: '0.2em 0.3em',
    background: '#ee3134',
    color: '#fff',
    'border-radius': '5px'
  },
  loginButton: {
    width: '100%'
  },
  loginBox: {
    flex: 1,
    display: 'flex',
    alignItems: 'center',
  },
  logoWrapper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    marginBottom: '1rem'
  },
  logo: {
    width: '100%',
    height: 60,
    marginBottom: '0.5em',
    backgroundImage: 'url(/logo.png)',
    backgroundSize: 'contain',
    backgroundRepeat: 'no-repeat',
  },
  copy: {
    fontSize: '0.6rem'
  }
});

const year = (new Date()).getFullYear();

const LoginPage = ({ theme }) => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const login = useLogin();
  const notify = useNotify();
  const classes = useStyles();
  const submit = e => {
    e.preventDefault();
    login({ email, password })
      .catch(() => notify('Invalid email or password'));
  };

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Box bgcolor="grey.300">
        <Container maxWidth="sm">
          <Box display="flex" alignItems="center" justifyContent="center" height="100vh" flexDirection="column">
            <Notification />
            <Box my="auto" className={classes.loginBox}>
              <Paper>
                <Box p={2}>
                  <h1>SIMAPP Backend {isStage && <span className={classes.stage}>Stage</span>}</h1>
                  <p>Please log in using your Office 365 account.</p>
                  <Button
                    variant="contained"
                    color="primary"
                    startIcon={<LoginIcon />}
                    href={`${API_URL}/login`}
                    className={classes.loginButton}
                  >
                    Log In
                  </Button>
                </Box>
              </Paper>
            </Box>
            <Box my="auto" className={classes.logoWrapper}>
              <div className={classes.logo} />
              <Typography className={classes.copy}>&copy;{year} Thermo Fisher Scientific Inc. All rights reserved.</Typography>
            </Box>
          </Box>
        </Container>
      </Box>
    </ThemeProvider>
  );
};

export default LoginPage;
