import * as React from 'react';
import PropTypes from 'prop-types';
import filesize from 'filesize';
import {
  get,
} from 'lodash';

const FileInfoField = ({ record = null }) => (
  record && (
    <React.Fragment>
      Name: {get(record, 'name', '')} |
      Size: {filesize(get(record, 'size', 0), { base: 10 })} |
      ID: {get(record, 'uploadId', '')}
    </React.Fragment>
  )
);

FileInfoField.propTypes = {
  record: PropTypes.object,
};

export default FileInfoField;
