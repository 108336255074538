import React from 'react';
import { AppBar as ReactAdminAppBar } from 'react-admin';
import { Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const isStage = (process.env.REACT_APP_IS_STAGE === 'true') || false;

const useStyles = makeStyles({
  title: {
    flex: 1,
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
  },
  stage: isStage ? {
    flex: '0 0 auto',
    'font-style': 'italic',
    'margin-left': '0.5em',
    'margin-right': '0.5em',
    padding: '0em 0.3em',
    background: '#ee3134',
    color: '#fff',
    'border-radius': '5px'
  } : {},
  version: {
    fontSize: "80%"
  }
});

const AppBar = props => {
  const classes = useStyles();
  return (
    <ReactAdminAppBar
      {...props}
    >
      <Typography
        variant="h6"
        color="inherit"
        className={classes.title}
        id="react-admin-title"
      />
      {isStage
        && (
          <Typography
            variant="h6"
            color="inherit"
            className={classes.stage}
          >
            Stage
          </Typography>
        )
      }
      <Typography
        variant="h6"
        color="inherit"
        className={classes.version}
      >
        SIMAPP v3.1.0
      </Typography>
    </ReactAdminAppBar>
  );
};

export default AppBar;
