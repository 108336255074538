import * as React from 'react';
import {
  Create,
  SimpleForm,
  usePermissions
} from 'react-admin';
import DistributorForm from './DistributorForm';
import FormToolbar from '../shared/FormToolbar';
import hasPermission from '../shared/hasPermission';

const DistributorEdit = props => {
  const { permissions } = usePermissions();
  const distributorDefaultValues = {
  };
  return (
    <Create {...props}>
      <SimpleForm
        toolbar={<FormToolbar />}
        initialValues={distributorDefaultValues}
        redirect="list"
      >
        <DistributorForm {...props} />
      </SimpleForm>
    </Create>
  )
};

export default DistributorEdit;
