import React, { useState, useEffect } from 'react';
import {
  TextInput,
  BooleanInput,
  SelectInput,
  required,
  email,
  maxLength,
  FormDataConsumer,
  Datagrid,
  FunctionField,
  Button,
} from 'react-admin';
import { useForm } from 'react-final-form';
import { find, clone, sortBy } from 'lodash';
import { Grid, Typography } from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import UndoIcon from '@material-ui/icons/Undo';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import dataProvider from '../../dataProvider.js';
import { humanize } from '../../helpers';
import simappColors from '../../theme/simapp-colors';
import { AutocompleteInput } from '../shared/AutocompleteInputMod';
import StatusTextField from '../shared/StatusTextField.js';

const AddUserField = (props) => {
  const [availableUsers, setAvailableUsers] = useState([]);
  useEffect(() => {
    dataProvider.getList('user_groups_search_user', {pagination: {perPage: 1000}}).then(result => {
      setAvailableUsers(result.data);
    })
  }, []);
  // const form = useForm();
  if(props.addToList.id && availableUsers.filter(obj => obj.id == props.addToList.id).length == 0) {
    setAvailableUsers([...availableUsers, props.addToList])
    props.resetAdd();
  }
  if(props.removeFromList.id && availableUsers.filter(obj => obj.id == props.removeFromList.id).length > 0 ) {
    setAvailableUsers(availableUsers.filter(obj => obj.id !== props.removeFromList.id ));
    props.resetRemove();
  }

  const addUser = newUser => {
    const newUserObj = newUser.obj;
    newUserObj.deleted_at = null;
    if(typeof props.formData.formData.assigned_users === 'undefined') props.formData.formData.assigned_users = {};
    props.formData.formData.assigned_users[newUserObj.id] = newUserObj
    // form.change('assigned_users', clone(props.formData.formData.assigned_users));
    document.getElementById("new_user").blur();
    setAvailableUsers(availableUsers.filter(d => !Object.keys(props.formData.formData.assigned_users).includes(`${d.id}`)));
  }

  return (
    <AutocompleteInput
      choices={sortBy(availableUsers.map(u => ({id: u.id, name: `${u.name} - ${u.email}`, obj: u})), ['name'])}
      source="new_user"
      onSelect={addUser}
      id="new_user"
      resettable={true}
      suggestionLimit={10}
      label="Add User"
    />
  )
}

const UnassignUserButton = (props) => {
  const form = useForm();

  const unassignUser = (e) => {
    e.preventDefault();
    props.addToList(props.formData.formData.assigned_users[props.record.id])
    props.formData.formData.assigned_users[props.record.id].deleted_at = 1;
    document.getElementById("changeb").getElementsByTagName('input')[0].click();
    form.change('assigned_users', clone(props.formData.formData.assigned_users));
  }

  return (
    <Button
      variant="outlined"
      onClick={unassignUser}
      color="default"
      title="Remove user from group"
      style={{ color: simappColors.strawberry }}
    >
      <DeleteIcon />
    </Button>
  );
};

const ReassignUserButton = ({ formData, record, removeFromList }) => {
  const form = useForm();

  const reassignUser = (e) => {
    e.preventDefault();
    removeFromList(formData.formData.assigned_users[record.id])
    formData.formData.assigned_users[record.id].deleted_at = null;
    form.change('assigned_users', clone(formData.formData.assigned_users));
  }

  return (
    <Button
      variant="outlined"
      onClick={reassignUser}
      color="default"
      title="Revert delete"
      // style={{ color: simappColors.strawberry }}
    >
      <UndoIcon />
    </Button>
  );
};

let addToList = {};
let removeFromList = {};

const UserGroupForm = () => {
  const [mainGroups, setMainGroups] = useState([]);
  useEffect(() => {
    dataProvider.getList('user_groups', {filter: {master_only: true}}).then(result => {
      setMainGroups(result.data);
    })
  }, []);

  return (
    <Grid
      container
      direction="row"
      justifyContent="center"
      alignItems="stretch"
      spacing={3}
    >
      {mainGroups.length > 0 && (
      <Grid item  xs={6}>
      <Typography variant="h6">SubGroup Information</Typography>
      <TextInput
          source="name"
          label="Company Name"
          fullWidth
          validate={[required(), maxLength(50)]}
        />
        <TextInput
          source="address"
          label="Address"
          fullWidth
          multiline
          rows={5}
          validate={[maxLength(500)]}
        />
        <TextInput
          source="contact_name"
          label="Contact Name"
          fullWidth
          validate={[maxLength(50)]}
        />
        <TextInput
          source="contact_email"
          label="Contact Email"
          validate={[email(), maxLength(50)]}
          type="email"
          fullWidth
        />
        <SelectInput
          source="parent_id"
          label="Main Group"
          choices={mainGroups}
          fullWidth
          validate={required()}
        />
        <Typography variant="h6">User Rights</Typography>
        <FormDataConsumer>
        {formDataProps => {
          const mainGroup = find(mainGroups, {id: formDataProps.formData.parent_id});
          const permissionSelectors = mainGroup ? Object.entries(mainGroup.permissions).map(p =>
          (<BooleanInput
            source={`permissions._${p[1]}`}
            label={humanize(p[0])}
            key={p[1]}
            fullWidth
          />)) : [(<p>Available once a main group has been selected.</p>)]

          return (<React.Fragment>
            {permissionSelectors}
          </React.Fragment>)
        }}
        </FormDataConsumer>
      </Grid>
      )}
      <Grid item xs={6}>
        <Typography variant="h6">SubGroup Members</Typography>
        {/*
          * The value of this input changed when a user is added/removed
          * to enable the "Save" Button
        */}
        <BooleanInput
          source="changed"
          id="changeb"
          style={{display: 'none'}}
        />
        <FormDataConsumer>
          {formDataProps => formDataProps.formData.assigned_users && (
            <Datagrid
              loaded
              ids={Object.keys(formDataProps.formData.assigned_users)}
              data={formDataProps.formData.assigned_users}
              currentSort={{ field: 'name', order: 'DESC' }}
            >
              <StatusTextField source="name" label="Name" />
              <StatusTextField source="mobile_phone" label="Phone" />
              <StatusTextField source="email" label="Email" />
              <FunctionField
                label=""
                textAlign="right"
                render={record => record.deleted_at ? (
                  <ButtonGroup>
                    <ReassignUserButton
                      record={record}
                      formData={formDataProps}
                      removeFromList={obj => removeFromList = obj}
                    />
                  </ButtonGroup>
                ) : (
                  <ButtonGroup>
                    <UnassignUserButton
                      record={record}
                      formData={formDataProps}
                      addToList={obj => addToList = obj}
                    />
                  </ButtonGroup>
                )}
              />
            </Datagrid>
          )}
        </FormDataConsumer>
        <FormDataConsumer>
          {formDataProps => (
            <AddUserField
              formData={formDataProps}
              addToList={addToList}
              removeFromList={removeFromList}
              resetAdd={() => addToList = {}}
              resetRemove={() => removeFromList = {}}
            />
          )}
        </FormDataConsumer>
      </Grid>
    </Grid>
  );
}

export default UserGroupForm;
