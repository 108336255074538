import React from 'react';
import { Layout } from 'react-admin';
import { makeStyles } from '@material-ui/core/styles';
import AppBar from './AppBar';
import Menu from './Menu';

const isStage = (process.env.REACT_APP_IS_STAGE === 'true') || false;

const useStageStyles = makeStyles({
  root: isStage ? {
    'border-left': '10px solid #ee3134',
    'box-sizing': 'border-box'
  } : {}
});

const AdminLayout = props => {
  const classes = useStageStyles();
  return (
    <Layout
      {...props}
      appBar={AppBar}
      classes={classes}
      menu={Menu}
    />
  );
};

export default AdminLayout;
