import * as React from 'react';
import {
  Edit,
  SimpleForm,
} from 'react-admin';
import ProductSubGroupForm from './ProductSubGroupForm';
import FormToolbar from '../shared/FormToolbar';

const redirect = basePath => basePath.replace('product_subgroups', 'product_groups');

const ProductSubGroupEdit = props => (
  <Edit {...props} undoable={false}>
    <SimpleForm
      toolbar={<FormToolbar />}
      redirect={redirect}
    >
      <ProductSubGroupForm {...props} />
    </SimpleForm>
  </Edit>
);

export default ProductSubGroupEdit;
