import * as React from 'react';
import {
  TextInput,
  NumberInput,
  ReferenceArrayInput,
  ReferenceInput,
  BooleanInput,
  AutocompleteInput,
  FormDataConsumer,
  AutocompleteArrayInput,
  required,
} from 'react-admin';
import { useForm } from 'react-final-form';
import GroupedArrayInput from '../shared/GroupedArrayInput';
import FileUploadInput from '../shared/FileUploadInput';

const ScientificLiteratureForm = props => (
  <React.Fragment>
    <FileUploadInput
      source="fileInfo"
      labelSingle="Upload Scientific Literature File"
      validate={required()}
    />
    <ReferenceInput
      source="sci_lit_content_type_id"
      label="Content Type"
      reference="scientific_literature_types"
      perPage={1000}
      fullWidth
      allowEmpty={false}
      validate={required()}
    >
      <AutocompleteInput
        optionText="name"
      />
    </ReferenceInput>
    <ReferenceArrayInput
      resource={props.resource}
      source="product_ids"
      reference="products_tree"
      label="Products"
      sort={{ field: 'name', order: 'ASC' }}
      perPage={1000}
      fullWidth
      validate={required()}
    >
      <FormDataConsumer>
        {formDataProps => (
          <GroupedArrayInput
            {...formDataProps}
            source="product_ids"
            groupSource="products"
            fullWidth
          />
        )}
      </FormDataConsumer>
    </ReferenceArrayInput>
    <TextInput
      source="title"
      validate={required()}
      fullWidth
    />
    <TextInput
      source="artile_number"
      label="Article Number"
      fullWidth
    />
    <TextInput
      source="bibliography_number"
      label="Bibliography Number"
      fullWidth
    />
    <TextInput
      source="author"
      label="Author(s)"
      helperText="Please separate authors with a comma"
      fullWidth
    />
    <NumberInput
      source="year"
      label="Year of publication"
      step={1}
      fullWidth
    />
    <TextInput
      source="published_in"
      label="Published in (Journal/Magazine)"
      fullWidth
    />
    <TextInput
      source="publication_detail"
      multiline
      label="Publication Details"
      fullWidth
      validate={required()}
    />
    <ReferenceArrayInput
      resource={props.resource}
      source="keyword_ids"
      reference="scientific_literature_keywords"
      label="Keywords"
      sort={{ field: 'name', order: 'ASC' }}
      perPage={25}
      fullWidth
    >
      <AutocompleteArrayInput
        optionText="name"
        fullWidth
      />
    </ReferenceArrayInput>
    <ReferenceInput
      source="language_id"
      reference="languages"
      label="Language"
      sort={{ field: 'name', order: 'ASC' }}
      perPage={1000}
      allowEmpty={false}
      validate={required()}
      fullWidth
    >
      <AutocompleteInput
        optionText="name"
        fullWidth
      />
    </ReferenceInput>
    <TextInput
      source="comment"
      label="Internal Comment"
      fullWidth
      multiline
    />
    <ReferenceArrayInput
      resource={props.resource}
      source="country_ids"
      reference="countries"
      label="Available in country"
      sort={{ field: 'name', order: 'ASC' }}
      perPage={1000}
      fullWidth
      validate={required()}
    >
      <FormDataConsumer>
        {formDataProps => (
          <GroupedArrayInput
            {...formDataProps}
            source="country_ids"
            groupSource="countries"
            fullWidth
          />
        )}
      </FormDataConsumer>
    </ReferenceArrayInput>
    <ReferenceArrayInput
      resource={props.resource}
      source="recommended_country_ids"
      reference="countries"
      label="Recommended for country"
      sort={{ field: 'name', order: 'ASC' }}
      perPage={1000}
      fullWidth
    >
      <FormDataConsumer>
        {formDataProps => (
          <GroupedArrayInput
            {...formDataProps}
            source="recommended_country_ids"
            groupSource="countries"
            fullWidth
          />
        )}
      </FormDataConsumer>
    </ReferenceArrayInput>
    <BooleanInput
      source="active"
      label="Make this content Active"
    />
    <BooleanInput
      source="shareable_link"
      label="Share this material as link"
    />
    <FormDataConsumer>
      {({ formData, ...rest }) => (
        formData.shareable_link && (
          <TextInput
            source="sharing_link"
            label="Sharing Link"
            type="url"
            helperText="A link to pubmed or similar is required to share Publications and Abstracts"
            fullWidth
          />
        )
      )}
    </FormDataConsumer>
    <BooleanInput
      source="shareable_document"
      label="Share this material as file"
    />
    <BooleanInput
      source="open_access"
      label="Open Access"
    />
    <BooleanInput
      source="reprint"
      label="Available for Reprint"
    />
    <BooleanInput
      source="available_for_distributors"
      label="Make this content available for Distributors"
    />
  </React.Fragment>
);

export default ScientificLiteratureForm;
