import ProductGroupIcon from '@material-ui/icons/ShoppingCart';
import ProductGroupList from './ProductGroupList';
import ProductGroupEdit from './ProductGroupEdit';
import ProductGroupCreate from './ProductGroupCreate';
import hasPermission from '../shared/hasPermission';

export default permissions => ({
  options: { label: 'Products' },
  list: hasPermission(permissions, { create: [
    '/api/web/product_groups',
    '/api/web/product_subgroups',
    '/api/web/products',
  ] })
    ? ProductGroupList : null,
  create: hasPermission(permissions, { create: ['/api/web/product_groups'] })
    ? ProductGroupCreate : null,
  edit: hasPermission(permissions, { update: ['/api/web/product_groups/'] })
    ? ProductGroupEdit : null,
  icon: ProductGroupIcon,
});
