import * as React from 'react';
import {
  Create,
  SimpleForm,
} from 'react-admin';
import NewsForm from './NewsForm';
import FormToolbar from '../shared/FormToolbar';

const newsDefaultValues = {
  type: 2
};

const NewsCreate = props => (
  <Create {...props}>
    <SimpleForm
      initialValues={newsDefaultValues}
      toolbar={<FormToolbar />}
      redirect="list"
    >
      <NewsForm {...props} />
    </SimpleForm>
  </Create>
);

export default NewsCreate;
