import * as React from 'react';
import {
  Create,
  SimpleForm,
} from 'react-admin';
import ContentPieceForm from './ContentPieceForm';
import FormToolbar from '../shared/FormToolbar';

const ContentPieceEdit = props => (
  <Create {...props}>
    <SimpleForm
      toolbar={<FormToolbar />}
      redirect="list"
    >
      <ContentPieceForm {...props} />
    </SimpleForm>
  </Create>
);

export default ContentPieceEdit;
