import * as React from 'react';
import {
  TextInput,
  ReferenceArrayInput,
  SelectInput,
  ReferenceInput,
  CheckboxGroupInput,
  BooleanInput,
  AutocompleteInput,
  FormDataConsumer,
  required,
} from 'react-admin';
import { useForm } from 'react-final-form';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import {
  includes,
  property,
  uniq,
  intersection,
} from 'lodash';
import GroupedArrayInput from '../shared/GroupedArrayInput';

const NEWS_TYPES = [
  'CONTENT',
  'COMPANY',
  'PRODUCT'
];

const CONTENT_TYPE = 1;
const PRODUCT_TYPE = 3;

const NewsForm = props => (
  <React.Fragment>
    <ReferenceArrayInput
      resource={props.resource}
      source="country_ids"
      reference="countries"
      sort={{ field: 'name', order: 'ASC' }}
      label="Available in country"
      perPage={1000}
      validate={required()}
      fullWidth
    >
      <FormDataConsumer>
        {formDataProps => (
          <GroupedArrayInput
            {...formDataProps}
            source="country_ids"
            groupSource="countries"
            validate={required()}
            fullWidth
          />
        )}
      </FormDataConsumer>
    </ReferenceArrayInput>
    <ReferenceArrayInput
      resource={props.resource}
      source="role_ids"
      reference="app_roles"
      label="Available in user group"
      validate={required()}
      fullWidth
    >
      <CheckboxGroupInput
        optionText="name"
        validate={required()}
      />
    </ReferenceArrayInput>
    <TextInput
      source="title"
      validate={required()}
      fullWidth
    />
    <TextInput
      source="subtitle"
      fullWidth
    />
    <TextInput
      multiline
      fullWidth
      source="content"
      validate={required()}
      resettable
    />
    <SelectInput
      source="type"
      choices={NEWS_TYPES.map((type, index) => ({ id: index + 1, name: type }))}
    />
    <FormDataConsumer>
      {({ formData, ...rest }) => {
        switch (formData.type) {
          case PRODUCT_TYPE: return (
            <ReferenceInput
              source="product_id"
              reference="products"
              sort={{ field: 'name', order: 'ASC' }}
              perPage={1000}
              fullWidth
              allowEmpty
            >
              <AutocompleteInput optionText={product => product.keyname || product.name} />
            </ReferenceInput>
          );
          case CONTENT_TYPE: return (
            <ReferenceInput
              source="content_id"
              reference="released_content_pieces"
              perPage={1000}
              fullWidth
              allowEmpty
            >
              <AutocompleteInput optionText="title" />
            </ReferenceInput>
          );
          default: return null;
        }
      }}
    </FormDataConsumer>
    <BooleanInput
      source="available_for_thermofisher"
      label="Make this news available for Thermofisher users"
    />
    <BooleanInput
      source="available_for_distributors"
      label="Make this news available for Distributors"
    />
  </React.Fragment>
);

export default NewsForm;
