import DistributorIcon from '@material-ui/icons/Person';
import DistributorCreate from './DistributorCreate';
import DistributorEdit from './DistributorEdit';
import DistributorList from './DistributorList';
import hasPermission from '../shared/hasPermission';

export default permissions => ({
  options: { label: 'App Users' },
  list: hasPermission(permissions, { read: ['/api/web/distributors'] })
    ? DistributorList : null,
  create: hasPermission(permissions, { create: ['/api/web/distributors'] })
    ? DistributorCreate : null,
  edit: hasPermission(permissions, { update: ['/api/web/distributors/'] })
    ? DistributorEdit : null,
  icon: DistributorIcon,
});
