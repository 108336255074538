import * as React from 'react';
import {
  TextInput,
  BooleanInput,
  ReferenceArrayInput,
  FormDataConsumer,
  required,
  email,
} from 'react-admin';
import GroupedArrayInput from '../shared/GroupedArrayInput';
import roles from '../shared/roles';

const API_URL = process.env.REACT_APP_API_URL;

const validateEmail = [required(), email()];

const ProductGroupForm = props => (
  <React.Fragment>
    <TextInput
      source="name"
      label="Name"
      validate={required()}
      fullWidth
    />
  </React.Fragment>
);

export default ProductGroupForm;
