import * as React from 'react';
import {
  TextInput,
  TextField,
  SelectInput,
  required,
  email,
} from 'react-admin';
import FileUploadInput from '../shared/FileUploadInput';
import roles from '../shared/roles';

const API_URL = process.env.REACT_APP_API_URL;

const validateEmail = [required(), email()];

const BackendUserForm = props => (
  <React.Fragment>
    <TextInput
      source="name"
      label="Full Name"
      fullWidth
      disabled={!!props.record.id}
      validate={required()}
    />
    <TextInput
      source="email"
      label="Email Adress"
      validate={validateEmail}
      type="email"
      fullWidth
    />
    <SelectInput
      source="role"
      label="Administrative Role"
      choices={roles}
      fullWidth
      validate={required()}
    />
  </React.Fragment>
);

export default BackendUserForm;
