import * as React from 'react';
import StorageIcon from '@material-ui/icons/Storage';
import ImportCreate from './ImportCreate';

export function qcvcImport() {
  return {
    create: props => (<ImportCreate title="Qcvc Import" {...props} />),
    icon: StorageIcon,
  };
}

export function accImport() {
  return {
    create: props => (<ImportCreate title="Acc Import" {...props} />),
    icon: StorageIcon,
  };
}
