const simappColors = {
  paleGrey: '#d2d3d5',
  palerGrey: '#efefef',
  black: '#000000',
  azul: '#00677F',
  pumpkin: '#ed7700',
  dark: '#0e0e10',
  darkTransparent: 'rgba(14, 14, 16, 0.5)',
  steelGrey: '#828487',
  white: '#ffffff',
  strawberry: '#ee3134',
  darkLimeGreen: '#7fba00',
  darkGrey: '#292b2e',
  lightGrey: '#B5B7B4',
  strangeGrey: '#C1C4A0',
  coolGrey: '#616265',
  tableBorder: '#c2c2c5',
  SelectedButtonColor: '#EFBF00',
  DefaultButtonColor: '#fff',
  DefaultButtonBorderColor: '#ccc',
  copeptinGrey: '#737172',
  copeptinStackBarChart: ['#737172', '#26bcd7', '#C3D1D1', '#C3AA1D', '#68C31D', '#1DB2C3', '#1D70C3', '#B21DC3', '#C31D5D', '#C33E1D'],
  copeptinTurqois: '#26bcd7',
  copeptinRed: '#d5030c',
  copeptinGreen: '#228b22',
  inputYellowColor: '#FFECAC',
  inputLightRedColor: '#FE3A29',
  disableButtonGrey: '#C2C2C5',
  placeholderGrey: '#b2b3b5'
};

export default simappColors;
