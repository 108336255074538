import * as React from 'react';
import {
  Create,
  SimpleForm,
} from 'react-admin';
import BackendUserForm from './BackendUserForm';
import FormToolbar from '../shared/FormToolbar';

const BackendUserEdit = props => (
  <Create {...props}>
    <SimpleForm
      toolbar={<FormToolbar />}
      redirect="list"
    >
      <BackendUserForm {...props} />
    </SimpleForm>
  </Create>
);

export default BackendUserEdit;
