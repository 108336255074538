import * as React from 'react';
import get from 'lodash/get';
import { TextField } from 'react-admin';
import red from '@material-ui/core/colors/red';
import grey from '@material-ui/core/colors/grey';

const StatusTextField = props => {
  const style = {};
  if(props.record) {
    if(
      (typeof props.record.deleted_at !== 'undefined' && props.record.deleted_at !== null) ||
      (typeof props.record.blocked_at !== 'undefined' && props.record.blocked_at !== null)
    ) {
      style.color = grey[500];
      style.fontStyle = 'italic';

    } else if(props.record.pristine) {
      style.color = red[600];
    }
  }
  const title = props.moreInTitle ? get(props.record, props.moreInTitle) : '';
  return (
   <TextField {...props} style={{ ...style, ...props.style }} title={title} />
  );
}

export default StatusTextField;
