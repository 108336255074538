import ConsumablesCalculatorAssayIcon from '@material-ui/icons/Widgets';
import ConsumablesCalculatorAssayCreate from './ConsumablesCalculatorAssayCreate';
import ConsumablesCalculatorAssayEdit from './ConsumablesCalculatorAssayEdit';
import ConsumablesCalculatorAssayList from './ConsumablesCalculatorAssayList';
import hasPermission from '../shared/hasPermission';

export default permissions => ({
  options: { label: 'CC Assay' },
  list: hasPermission(permissions, { read: ['/api/web/consumables_calculator_assays'] })
    ? ConsumablesCalculatorAssayList : null,
  create: hasPermission(permissions, { create: ['/api/web/consumables_calculator_assays'] })
    ? ConsumablesCalculatorAssayCreate : null,
  edit: hasPermission(permissions, { update: ['/api/web/consumables_calculator_assays/'] })
    ? ConsumablesCalculatorAssayEdit : null,
  icon: ConsumablesCalculatorAssayIcon,
});
