import { createMuiTheme } from '@material-ui/core/styles';
import { light as palette } from '@material-ui/core/styles/createPalette';
import simappColors from './simapp-colors';

const simappTheme = createMuiTheme({
  palette: {
    primary: {
      main: simappColors.azul
    },
    secondary: {
      main: simappColors.darkGrey
    },
    error: {
      main: simappColors.strawberry
    },
    background: {
      default: simappColors.palerGrey
    },
    text: {
      primary: simappColors.dark,
      secondary: simappColors.coolGrey,
      disabled: 'rgba(0, 0, 0, 0.38)',
      hint: 'rgba(0, 0, 0, 0.38)',
    },
    divider: simappColors.dark,
  },
  typography: {
    fontFamily: 'helvetica-neue-lt, sans-serif',
    useNextVariants: true,
  },
  overrides: {
    MuiCard: {
      root: {
        overflowX: 'auto'
      },
    },
    MuiTableCell: {
      root: {
        height: 34 // standard height for rows with actions in datagrid
      },
      head: {
        fontWeight: 'bold',
        whiteSpace: 'nowrap'
      },
      paddingCheckbox: {
        display: 'none' // dirty workaround to hide checkboxcolumn in datagrid
      }
    },
    RaMenuItemLink: {
      root: {
        paddingTop: 10,
        paddingBottom: 10
      }
    },
    RaToolbar: {
      toolbar: {
        '& > *': {
          marginRight: 20
        }
      }
    }
  },
  props: {
    MuiButtonBase: {
      disableRipple: true, // No more ripple, on the whole application
    },
  },
});

export default simappTheme;
