import UserGroupIcon from '@material-ui/icons/GroupWork';
import UserGroupCreate from './UserGroupCreate';
import UserGroupEdit from './UserGroupEdit';
import UserGroupList from './UserGroupList';
import hasPermission from '../shared/hasPermission';

export default permissions => ({
  options: { label: 'CMT Groups' },
  list: hasPermission(permissions, { read: ['/api/web/user_groups'] }) ? UserGroupList : null,
  create: hasPermission(permissions, { create: ['/api/web/user_groups'] }) ? UserGroupCreate : null,
  edit: hasPermission(permissions, { update: ['/api/web/user_groups/'] }) ? UserGroupEdit : null,
  icon: UserGroupIcon,
});
