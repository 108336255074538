import * as React from 'react';
import {
  DateField,
} from 'react-admin';
import {
  get,
  set,
} from 'lodash';
import isPublished from './isPublished';
import hasUnpublishedChanges from './hasUnpublishedChanges';

const PublishDateField = props => {
  const publishedAt = get(props.record, props.source);

  if (!isPublished(props.record)) {
    return (
      <React.Fragment>
        Not yet published
      </React.Fragment>
    );
  }

  const record = {};
  set(record, props.source, publishedAt);

  return (
    <React.Fragment>
      <DateField {...props} record={record} />
      {hasUnpublishedChanges(props.record) && <strong><br />with unpublished changes</strong>}
    </React.Fragment>
  );
};

export default PublishDateField;
