import ScientificLiteratureIcon from '@material-ui/icons/Book';
import ScientificLiteratureCreate from './ScientificLiteratureCreate';
import ScientificLiteratureEdit from './ScientificLiteratureEdit';
import ScientificLiteratureList from './ScientificLiteratureList';
import hasPermission from '../shared/hasPermission';

export default permissions => ({
  list: hasPermission(permissions, { read: ['/api/web/scientific_literatures'] })
    ? ScientificLiteratureList : null,
  create: hasPermission(permissions, { create: ['/api/web/scientific_literatures'] })
    ? ScientificLiteratureCreate : null,
  edit: hasPermission(permissions, { update: ['/api/web/scientific_literatures/'] })
    ? ScientificLiteratureEdit : null,
  icon: ScientificLiteratureIcon,
});
