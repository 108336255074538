import * as React from 'react';
import {
  Edit,
  SimpleForm,
} from 'react-admin';
import ContentTemplateForm from './ContentTemplateForm';
import FormToolbar from '../shared/FormToolbar';

const ContentTemplateEdit = props => (
  <Edit {...props} undoable={false}>
    <SimpleForm toolbar={<FormToolbar />}>
      <ContentTemplateForm {...props} />
    </SimpleForm>
  </Edit>
);

export default ContentTemplateEdit;
