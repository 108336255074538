import * as React from 'react';
import { cloneElement, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import {
  List,
  Pagination,
  Datagrid,
  BooleanField,
  FunctionField,
  BooleanInput,
  Filter,
  SearchInput,
  Button,
  EditButton,
  DeleteButton,
  Confirm,

  useListContext,
  useRefresh,
  useNotify,
  TopToolbar,
  CreateButton,
  sanitizeListRestProps,
  usePermissions,
} from 'react-admin';

const SimappPagination = props => <Pagination rowsPerPageOptions={[15]} {...props} />;


const SimappList = props => (
  <List
    perPage={15}
    pagination={<SimappPagination />}
    {...props}
  />
)

export default SimappList;
