import * as React from 'react';
import { isFunction } from 'lodash';
import {
  SaveButton,
  ListButton,
  DeleteButton,
  Toolbar,
} from 'react-admin';
import CancelIcon from '@material-ui/icons/Close';

const valueOrDefault = (value, defaultValue) => (typeof value === 'undefined' ? defaultValue : value);

const FormToolbar = props => {
  // Use form pristine to enable or disable the save button
  // if alwaysEnableSaveButton is undefined
  const disabled = !valueOrDefault(props.alwaysEnableSaveButton, !props.pristine);
  return (
    <Toolbar {...props} undoable={false}>
      <SaveButton disabled={disabled} />
      <ListButton
        // XXX: this is a bit hacky, but we need this to return from the
        //     ProductCreate/Edit and ProductSubGroupCreate/Edit forms to the
        //     ProductGroupList
        //     the redirect prop from the SimpleForm is given to the toolbar too
        to={isFunction(props.redirect) ? props.redirect(props.basePath) : props.basePath}
        label="Cancel"
        icon={<CancelIcon />}
      />
      {/* {props.record && typeof props.record.id !== 'undefined' && (
        <DeleteButton undoable={false} />
      )} */}
    </Toolbar>
  );
};

export default FormToolbar;
