import * as React from 'react';
import PublishIcon from '@material-ui/icons/Publish';
import {
  SaveButton,
  Toolbar,
} from 'react-admin';

const ImportFormToolbar = props => (
  <Toolbar {...props} undoable={false}>
    <SaveButton label="Import" icon={<PublishIcon />} />
  </Toolbar>
);

export default ImportFormToolbar;
