import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  FileInput,
  useNotify
} from 'react-admin';
import { useForm } from 'react-final-form';
import FileInfoField from './FileInfoField';
import ProgressBar from './ProgressBar';
import uploadFile, { cancelUpload } from './uploadFile';

const API_URL = process.env.REACT_APP_API_URL;

const FileUploadInput = props => {
  const form = useForm();
  const notify = useNotify();
  const [progress, setProgress] = useState({
    percent: 0
  });
  const [uploading, setUploading] = useState({
    state: false
  });

  const updateProgress = val => {
    setProgress({
      percent: val,
    });
  };

  const cancel = () => {
    setUploading({ state: false });
    cancelUpload();
  };

  return (
    <React.Fragment>
      <div style={{ visibility: uploading.state ? 'hidden' : 'visible' }}>
        <FileInput
          multiple={false}
          accept={props.accept}
          options={{ onDrop: files => {
            form.change(props.source, undefined);
            setUploading({ state: true });
            uploadFile(files[0], props.uploadUrl, updateProgress)
              .then(fileInfo => {
                setUploading({ state: false });
                form.change(props.source, fileInfo);
              })
              .catch(e => {
                setUploading({ state: false });
                form.change(props.source, undefined);
                notify(e.message, 'warning');
              });
          } }}
          {...props}
        >
          <FileInfoField
            source={props.source}
            record={props.record}
          />
        </FileInput>
      </div>
      {uploading.state && (
        <ProgressBar progress={progress} onCancel={cancel} />
      )}
    </React.Fragment>
  );
};

FileUploadInput.propTypes = {
  source: PropTypes.string.isRequired,
  record: PropTypes.object,
  uploadUrl: PropTypes.string.isRequired,
};
FileUploadInput.defaultProps = {
  source: 'fileInfo',
  uploadUrl: `${API_URL}/upload/files`,
};

export default FileUploadInput;
