import * as React from 'react';
import { cloneElement, useMemo } from 'react';
import PropTypes from 'prop-types';
import {
  Datagrid,
  FunctionField,
  TextField,
  DateField,
  Filter,
  SearchInput,
  EditButton,
  DeleteButton,
  useListContext,
  TopToolbar,
  CreateButton,
  sanitizeListRestProps,
  usePermissions,
} from 'react-admin';

import ButtonGroup from '@material-ui/core/ButtonGroup';
import hasPermission from '../shared/hasPermission';
import { sanitizeButtonProps } from '../shared/sanitizeReactProps';
import List from '../shared/SimappList';

const NewsActions = props => {
  const {
    className,
    exporter,
    filters,
    maxResults,
    ...rest
  } = props;
  const {
    currentSort,
    resource,
    displayedFilters,
    filterValues,
    hasCreate,
    basePath,
    selectedIds,
    showFilter,
    total,
  } = useListContext();
  const { permissions } = usePermissions();

  return hasPermission(permissions, { create: ['/api/web/news'] })
    ? (
      <TopToolbar
        className={className}
        {...sanitizeListRestProps(rest)}
      >
        {filters && cloneElement(filters, {
          resource,
          showFilter,
          displayedFilters,
          filterValues,
          context: 'button',
        })}
        <CreateButton basePath={basePath} />

      </TopToolbar>
    ) : null;
};

const NewsFilter = props => (
  <Filter {...props}>
    <SearchInput source="q" alwaysOn />
  </Filter>
);

const NewsPanel = ({ id, record, resource }) => (
  <div dangerouslySetInnerHTML={{ __html: record.content }} />
);

const NewsList = props => {
  const { permissions } = usePermissions();
  return (
    <List
      {...props}
      title="News"
      filters={<NewsFilter />}
      actions={<NewsActions />}
      sort={{ order: 'DESC', field: 'id' }}
    >
      <Datagrid expand={<NewsPanel />}>
        <TextField source="id" label="ID" />
        <TextField source="title" />
        <DateField
          source="created_at"
          showTime
        />
        <FunctionField
          label="Actions"
          textAlign="right"
          render={record => (
            <ButtonGroup>
              {hasPermission(permissions, { update: ['/api/web/news/'] })
            && (
              <EditButton
                {...sanitizeButtonProps(props)}
                title="Edit"
                label={null}
                record={record}
              />
            )}
              {hasPermission(permissions, { delete: ['/api/web/news/'] })
            && (
              <DeleteButton
                {...sanitizeButtonProps(props)}
                title="Delete"
                undoable={false}
                label={null}
                record={record}
              />
            )}
            </ButtonGroup>
          )}
        />
      </Datagrid>
    </List>
  );
};

export default NewsList;
