import BackendUserIcon from '@material-ui/icons/People';
import BackendUserCreate from './BackendUserCreate';
import BackendUserEdit from './BackendUserEdit';
import BackendUserList from './BackendUserList';
import hasPermission from '../shared/hasPermission';

export default permissions => ({
  options: { label: 'Backend Users' },
  list: hasPermission(permissions, { read: ['/api/web/backend_users'] }) ? BackendUserList : null,
  create: hasPermission(permissions, { create: ['/api/web/backend_users'] }) ? BackendUserCreate : null,
  edit: hasPermission(permissions, { update: ['/api/web/backend_users/'] }) ? BackendUserEdit : null,
  icon: BackendUserIcon,
});
