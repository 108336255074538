import * as React from 'react';
import {
  Create,
  SimpleForm,
} from 'react-admin';
import ProductForm from './ProductForm';
import FormToolbar from '../shared/FormToolbar';

const redirect = basePath => basePath.replace('products', 'product_groups');

const ProductEdit = props => (
  <Create {...props}>
    <SimpleForm
      toolbar={<FormToolbar />}
      redirect={redirect}
    >
      <ProductForm {...props} />
    </SimpleForm>
  </Create>
);

export default ProductEdit;
