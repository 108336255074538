import {
  assign,
  get,
  partial,
  flow,
  sortBy,
} from 'lodash';
import { HttpError } from 'react-admin';
import { encode } from 'qss';

const API_URL = process.env.REACT_APP_API_URL;

const listOnlyResources = [
  'products_tree',
  'products_and_groups_tree',
  'released_content_pieces',
  'countries',
];

function isResourceListOnly(resource) {
  return listOnlyResources.indexOf(resource) !== -1;
}

function fetchJson(url, options) {
  return fetch(url, assign({
    credentials: 'include',
    mode: 'cors',
    headers: {
      'Content-Type': 'application/json'
    }
  }, options))
    .then(res => {
      if (res.ok) {
        return res.json();
      }
      return res.json()
        .then(json => {
          throw new HttpError(
            get(json, 'msg', res.statusText),
            res.status,
            json
          );
        });
    });
}

function getUrlForResource(resource) {
  return `${API_URL}/${resource}`;
}

const dataProvider = {
  getList: (resource, params) => {
    const url = getUrlForResource(resource);
    const query = assign({
      page: get(params, 'pagination.page', 1),
      per_page: get(params, 'pagination.perPage', 15),
      order_by: get(params, 'sort.field', null),
      order_dir: get(params, 'sort.order', 'ASC'),
    }, params.filter || {});

    return fetchJson(`${url}?${encode(query)}`)
      .then(res => ({
        data: get(res, 'data', res),
        total: get(res, 'meta.count', res.length),
      }));
  },
  getOne: (resource, params) => {
    if (isResourceListOnly(resource)) {
      return Promise.resolve({ data: null });
    }
    const url = `${getUrlForResource(resource)}/${params.id}`;

    return fetchJson(url)
      .then(data => ({
        data
      }));
  },
  getMany: (resource, params) => {
    if (isResourceListOnly(resource)) {
      return Promise.resolve({ data: [] });
    }
    const url = getUrlForResource(resource);

    return Promise.all(
      params.ids.map(id => fetchJson(`${url}/${id}`))
    )
      .then(results => ({
        data: results
      }));
  },
  getManyReference: (resource, params) => {
    const url = getUrlForResource(resource);
    const query = assign({
      page: get(params, 'pagination.page', 1),
      per_page: get(params, 'pagination.perPage', 15),
      order_by: get(params, 'sort.field', null),
      order_dir: get(params, 'sort.order', 'ASC'),
      [params.target]: get(params, 'id'),
    }, params.filter || {});

    return fetchJson(`${url}?${encode(query)}`)
      .then(res => ({
        data: get(res, 'data', res),
        total: get(res, 'meta.count', res.length),
      }));
  },
  create: (resource, params) => {
    const url = getUrlForResource(resource);

    return fetchJson(url, {
      method: 'POST',
      body: JSON.stringify(params.data)
    })
      .then(data => ({
        data
      }));
  },
  update: (resource, params) => {
    const url = `${getUrlForResource(resource)}/${params.id}`;

    return fetchJson(url, {
      method: 'PUT',
      body: JSON.stringify(params.data)
    })
      .then(data => ({
        data
      }));
  },
  updateMany: (resource, params) => {
    const url = getUrlForResource(resource);
    return Promise.all(
      params.data.map(entry => fetchJson(`${url}/${entry.id}`, {
        method: 'PUT',
        body: JSON.stringify(entry)
      })
        .then(data => data))
    )
      .then(results => ({
        data: results
      }));
  },
  delete: (resource, params) => {
    const url = `${getUrlForResource(resource)}/${params.id}`;

    return fetchJson(url, {
      method: 'DELETE',
    })
      .then(data => ({
        data
      }));
  },
  deleteMany: (resource, params) => {
    const url = getUrlForResource(resource);
    return Promise.all(
      params.ids.map(id => fetchJson(`${url}/${id}`, {
        method: 'DELETE',
      })
        .then(data => data))
    )
      .then(results => ({
        data: results
      }));
  },
};

export default dataProvider;
