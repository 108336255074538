import * as React from 'react';
import { cloneElement } from 'react';
import {
  List,
  Datagrid,
  FunctionField,
  Button,
  TextField,
  EditButton,
  DeleteButton,
  ReferenceManyField,
  useListContext,
  TopToolbar,
  CreateButton,
  sanitizeListRestProps,
  usePermissions,
} from 'react-admin';

import { makeStyles } from '@material-ui/core/styles';
import DownloadIcon from '@material-ui/icons/GetApp';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import hasPermission from '../shared/hasPermission';
import { sanitizeButtonProps } from '../shared/sanitizeReactProps';

const API_URL = process.env.REACT_APP_API_URL;
const productSubGroupsBasePath = '/product_subgroups';
const productsBasePath = '/products';

const useStyles = makeStyles(
  theme => ({
    subLevelProductSubgroup: {
      marginLeft: 32,
      width: 'calc(100% - 16px)',
      '&:last-child': {
        marginBottom: -7
      }
    },
    subLevelProduct: {
      marginLeft: 80,
      width: 'calc(100% - 64px)',
      '&:last-child': {
        marginBottom: -7
      }
    }
  }),
  { name: 'ProductGroupList' }
);

const ProductGroupActions = props => {
  const {
    className,
    exporter,
    filters,
    maxResults,
    ...rest
  } = props;
  const {
    resource,
    displayedFilters,
    filterValues,
    basePath,
    showFilter,
  } = useListContext();
  const { permissions } = usePermissions();

  return (
    <TopToolbar
      className={className}
      {...sanitizeListRestProps(rest)}
    >
      {filters && cloneElement(filters, {
        resource,
        showFilter,
        displayedFilters,
        filterValues,
        context: 'button',
      })}
      {hasPermission(permissions, { create: ['/api/web/product_groups'] })
      && (
        <CreateButton
          basePath={basePath}
          label="Create Product Group"
        />
      )}
      {hasPermission(permissions, { create: ['/api/web/product_subgroups'] })
      && (
        <CreateButton
          basePath={productSubGroupsBasePath}
          label="Create Product Sub Group"
        />
      )}
      {hasPermission(permissions, { create: ['/api/web/products'] })
      && (
        <CreateButton
          basePath={productsBasePath}
          label="Create Product"
        />
      )}
      {hasPermission(permissions, { read: ['/api/web/products_csv'] })
      && (
        <Button
          href={`${API_URL}/products_csv`}
          label="Download CSV"
        >
          <DownloadIcon />
        </Button>
      )
      }
    </TopToolbar>
  );
};

const ProductGroupPanel = props => {
  const { permissions } = usePermissions();
  const classes = useStyles(props);

  return (
    <React.Fragment>
      <ReferenceManyField
        {...props}
        reference="product_subgroups"
        sort={{ field: 'name', order: 'ASC' }}
        target="product_group_id"
        perPage={1000}
      >
        <Datagrid
          expand={<ProductSubGroupPanel />}
          className={classes.subLevelProductSubgroup}
        >
          <TextField source="name" label="Product Sub Group" />
          <FunctionField
            label="Actions"
            textAlign="right"
            render={record => (
              <ButtonGroup>
                {hasPermission(permissions, { update: ['/api/web/product_subgroups/'] })
              && (
                <EditButton
                  {...sanitizeButtonProps(props)}
                  title="Edit Product Sub Group"
                  label={null}
                  record={record}
                  basePath={productSubGroupsBasePath}
                />
              )}
                {hasPermission(permissions, { delete: ['/api/web/product_subgroups/'] })
              && (
                <DeleteButton
                  {...sanitizeButtonProps(props)}
                  title="Delete Product Sub Group"
                  undoable={false}
                  label={null}
                  record={record}
                  resource="product_subgroups" // a bug in ra does not propagate "basePath" correctly, so we need to set resource (instead)
                  redirect="product_groups"
                  basePath={productSubGroupsBasePath}
                />
              )}
              </ButtonGroup>
            )}
          />
        </Datagrid>
      </ReferenceManyField>
      <ReferenceManyField
        {...props}
        reference="products"
        sort={{ field: 'name', order: 'ASC' }}
        target="product_group_id"
        filter={{ product_subgroup_id: null }}
        perPage={1000}
      >
        <Datagrid className={classes.subLevelProduct}>
          <TextField source="name" label="Product" />
          <FunctionField
            label="Actions"
            textAlign="right"
            render={record => (
              <ButtonGroup>
                {hasPermission(permissions, { update: ['/api/web/products/'] })
              && (
                <EditButton
                  {...sanitizeButtonProps(props)}
                  title="Edit Product"
                  label={null}
                  record={record}
                  basePath={productsBasePath}
                />
              )}
                {hasPermission(permissions, { delete: ['/api/web/products/'] })
              && (
                <DeleteButton
                  {...sanitizeButtonProps(props)}
                  title="Delete Product"
                  undoable={false}
                  label={null}
                  record={record}
                  resource="products"
                  redirect="product_groups"
                  basePath={productsBasePath}
                />
              )}
              </ButtonGroup>
            )}
          />
        </Datagrid>
      </ReferenceManyField>
    </React.Fragment>
  );
};

const ProductSubGroupPanel = props => {
  const { permissions } = usePermissions();
  const classes = useStyles(props);

  return (
    <ReferenceManyField
      {...props}
      reference="products"
      sort={{ field: 'name', order: 'ASC' }}
      target="product_subgroup_id"
      perPage={1000}
    >
      <Datagrid className={classes.subLevelProduct}>
        <TextField source="name" label="Product" />
        <FunctionField
          label="Actions"
          textAlign="right"
          render={record => (
            <ButtonGroup>
              {hasPermission(permissions, { update: ['/api/web/products/'] })
            && (
              <EditButton
                {...sanitizeButtonProps(props)}
                title="Edit Product"
                label={null}
                record={record}
                basePath={productsBasePath}
              />
            )}
              {hasPermission(permissions, { delete: ['/api/web/products/'] })
            && (
              <DeleteButton
                {...sanitizeButtonProps(props)}
                title="Delete Product"
                undoable={false}
                label={null}
                record={record}
                resource="products"
                redirect="product_groups"
                basePath={productsBasePath}
              />
            )}
            </ButtonGroup>
          )}
        />
      </Datagrid>
    </ReferenceManyField>
  );
};

const ProductGroupList = props => {
  const { permissions } = usePermissions();
  return (
    <List
      {...props}
      title="Products"
      sort={{ order: 'ASC', field: 'name' }}
      perPage={25}
      actions={<ProductGroupActions />}
    >
      <Datagrid expand={<ProductGroupPanel />}>
        <TextField source="name" label="Product Group" />
        <FunctionField
          label="Actions"
          textAlign="right"
          render={record => (
            <ButtonGroup>
              {hasPermission(permissions, { update: ['/api/web/product_groups/'] })
            && (
              <EditButton
                {...sanitizeButtonProps(props)}
                title="Edit Product Group"
                label={null}
                record={record}
              />
            )}
              {hasPermission(permissions, { delete: ['/api/web/product_groups/'] })
            && (
              <DeleteButton
                {...sanitizeButtonProps(props)}
                title="Delete Product Group"
                undoable={false}
                label={null}
                record={record}
              />
            )}
            </ButtonGroup>
          )}
        />
      </Datagrid>
    </List>
  );
};

export default ProductGroupList;
