import * as React from 'react';
import { cloneElement, useMemo } from 'react';
import PropTypes from 'prop-types';
import {
  Datagrid,
  FunctionField,
  TextField,
  SelectField,
  Filter,
  SearchInput,
  EditButton,
  DeleteButton,

  useListContext,
  usePermissions,
  useAuthProvider,
  TopToolbar,
  CreateButton,
  sanitizeListRestProps,
} from 'react-admin';

import { get } from 'lodash';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import roles from '../shared/roles';
import hasPermission from '../shared/hasPermission';
import { sanitizeButtonProps } from '../shared/sanitizeReactProps';
import List from '../shared/SimappList';

const BackendUserActions = props => {
  const {
    className,
    exporter,
    filters,
    maxResults,
    ...rest
  } = props;
  const {
    currentSort,
    resource,
    displayedFilters,
    filterValues,
    hasCreate,
    basePath,
    selectedIds,
    showFilter,
    total,
  } = useListContext();
  const { permissions } = usePermissions();

  return hasPermission(permissions, { create: '/api/web/backend_users' }) ? (
    <TopToolbar
      className={className}
      {...sanitizeListRestProps(rest)}
    >
      {filters && cloneElement(filters, {
        resource,
        showFilter,
        displayedFilters,
        filterValues,
        context: 'button',
      })}
      <CreateButton basePath={basePath} />
    </TopToolbar>
  ) : null;
};

const UserDeleteButton = props => {
  const authProvider = useAuthProvider();
  const session = authProvider.getSession();
  const isCurrentUser = get(session, 'user.id', null) === props.record.id;

  return isCurrentUser
    ? null
    : (
      <DeleteButton
        {...sanitizeButtonProps(props)}
        title="Delete"
        label={null}
        undoable={false}
      />
    );
};

const BackendUserFilter = props => (
  <Filter {...props}>
    <SearchInput source="q" alwaysOn />
  </Filter>
);

const BackendUserList = props => {
  const { permissions } = usePermissions();
  const authProvider = useAuthProvider();

  return (
    <List
      {...props}
      filters={<BackendUserFilter />}
      actions={<BackendUserActions />}
      sort={{ order: 'DESC', field: 'id' }}
    >
      <Datagrid
        isRowSelectable={record => {
          const session = authProvider.getSession();
          return !get(session, 'user.id', null) === record.id;
        }}
      >
        <TextField source="id" label="ID" />
        <TextField source="email" label="Email" />
        <TextField source="name" label="Name" />
        <SelectField source="role" label="Role" choices={roles} />
        <FunctionField
          label="Actions"
          textAlign="right"
          render={record => (
            <ButtonGroup>
              {hasPermission(permissions, { update: '/api/web/backend_users/' })
            && (
              <EditButton
                {...sanitizeButtonProps(props)}
                title="Edit"
                label={null}
                record={record}
              />
            )}
              {hasPermission(permissions, { delete: '/api/web/backend_users/' })
            && (
              <UserDeleteButton
                {...sanitizeButtonProps(props)}
                record={record}
              />
            )}
            </ButtonGroup>
          )}
        />
      </Datagrid>
    </List>
  );
};

export default BackendUserList;
