import * as React from 'react';
import {
  TextInput,
  required,
} from 'react-admin';
import { useForm } from 'react-final-form';
import Grid from '@material-ui/core/Grid';
import GroupedArrayInput from '../shared/GroupedArrayInput';
import CollapsibleArrayInput from '../shared/CollapsibleArrayInput';

const ConsumablesCalculatorMultiControlForm = props => (
  <React.Fragment>
    <TextInput
      source="name"
      label="Product Name"
      validate={required()}
      fullWidth
    />
    <TextInput
      source="short_name"
      label="Short Name"
      fullWidth
    />
    <TextInput
      source="ref"
      label="Reference Number"
      validate={required()}
      fullWidth
    />
  </React.Fragment>
);

export default ConsumablesCalculatorMultiControlForm;
