import * as React from 'react';
import { BooleanField } from 'react-admin';

import red from '@material-ui/core/colors/red';
import grey from '@material-ui/core/colors/grey';

const StatusBooleanField = props => {
  let color = '';
  if(props.record) {
    if(
      (typeof props.record.deleted_at !== 'undefined' && props.record.deleted_at !== null) ||
      (typeof props.record.blocked_at !== 'undefined' && props.record.blocked_at !== null)
    ) {
      color = grey[500];
    } else if(props.record.pristine) {
      color = red[600];
    }
  }
  return (
   <BooleanField  {...props} style={{ color, ...props.style }} />
  );
}

export default StatusBooleanField;
