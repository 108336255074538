import {
  get,
  includes,
  isArray,
} from 'lodash';

/**
 * Checks if user has permission to access an endpoint
 * @param  {Object}  perms        Permissions as provided by /api/web/load_auth
 * @param  {Object}  [actions={[action]: [routes]}] Object where keys are actions (create, update, read, delete) and values are lists of routes
 * @return {Boolean}              True if user has access or False if any permission is not granted
 */
export default function hasPermission(perms, actions = {}) {
  for (const action in actions) {
    let routes = actions[action];
    if (!isArray(routes)) {
      routes = [routes];
    }

    for (let i = 0; i < routes.length; i++) {
      const route = routes[i];
      const perm = get(perms, `${action}.${route}`, false);

      // permission not present,
      // so bail out early
      if (!perm) {
        return false;
      }

      if (!(
        perm === true
        || includes(perm, '*')
        || includes(perm, '+')
        || includes(perm, action.substring(0, 1).toUpperCase())
      )) {
        return false;
      }
    }
  }

  return true;
}
