import * as React from 'react';
import {
  TextInput,
  required,
} from 'react-admin';

const ScientificLiteratureKeywordForm = props => (
  <React.Fragment>
    <TextInput
      source="name"
      validate={required()}
      fullWidth
    />
  </React.Fragment>
);

export default ScientificLiteratureKeywordForm;
