import * as React from 'react';
import PropTypes from 'prop-types';
import {
  get,
} from 'lodash';
import { useForm, Field } from 'react-final-form';

const API_URL = process.env.REACT_APP_API_URL;

const iframeStyle = {
  width: 1024,
  height: 768,
  border: '1px solid #eee'
};

const BACKEND_URL = process.env.REACT_APP_BACKEND_URL;
const BACKEND_UI_URL = process.env.REACT_APP_BACKEND_UI_URL;

class TemplatedContentInputInner extends React.Component {
  constructor(props) {
    super(props);
    this.iframeRef = null;
    this.templateId = null;
  }

  componentDidMount() {
    this.initIframe();
  }

  componentDidUpdate() {
    if (this.iframeRef && this.templateId !== this.getTemplateId()) {
      this.initIframe();
    }
  }

  getTemplateId() {
    return get(this.props.formData, this.props.templateSource, null);
  }

  attachIFrameListeners() {
    this.iframeRef.contentWindow.document.body.addEventListener(
      'editablecontentchange',
      this.updateContent.bind(this)
    );
  }

  clearIframe() {
    this.iframeRef.setAttribute('src', '');
    this.iframeRef.setAttribute('src', 'about:blank');
  }

  initIframe() {
    this.templateId = this.getTemplateId();
    const contentId = this.props.record.id || null;

    if (!(this.templateId)) {
      return null;
    }
    const previewUrl = contentId
      ? `${API_URL}/preview/${this.templateId}/${contentId}`
      : `${API_URL}/preview/${this.templateId}`;

    this.clearIframe();

    fetch(previewUrl, {
      credentials: 'include'
    })
      .then(res => {
        if (res.ok) {
          return res.text();
        }
        return res.text()
          .then(msg => {
            throw new Error(msg);
          });
      })
      .then(html => {
        const _html = html
          .replace('<head>', `<head>
          <base href="${BACKEND_URL}">
          <link rel="stylesheet" href="${BACKEND_UI_URL}/editable_content.css">
          <link rel="stylesheet" href="${BACKEND_UI_URL}/editable_image.css">`)
          .replace('</body>', `
          <script src="${BACKEND_UI_URL}/vendor/ckeditor/ckeditor.js"></script>
          <script src="${BACKEND_UI_URL}/editable_content.js"></script>
          <script src="${BACKEND_UI_URL}/editable_image.js"></script>
          </body>`);

        this.iframeRef.contentWindow.document.open();
        this.iframeRef.contentWindow.document.write(_html);
        this.iframeRef.contentWindow.document.close();

        this.attachIFrameListeners();
      }, err => {
        this.iframeRef.contentWindow.document.open();
        this.iframeRef.contentWindow.document.write(err.toString());
        this.iframeRef.contentWindow.document.close();
        console.error(err);
      });
  }

  updateContent() {
    const content = {};
    const editableHTMLElements = Array.from(this.iframeRef.contentWindow.document.querySelectorAll('[id^="editable-"]'));
    editableHTMLElements.forEach(el => {
      const id = el.getAttribute('id');
      const html = el.innerHTML;
      content[id] = html;
    });

    const editableImageElements = Array.from(this.iframeRef.contentWindow.document.querySelectorAll('[id^="editable_img-"]'));
    editableImageElements.forEach(el => {
      const id = el.getAttribute('id');
      const src = el.getAttribute('src');
      if (src.indexOf('data:') === 0) {
        content[id] = src;
      }
    });

    this.props.form.change(this.props.source, content);
  }

  renderField(fieldProps) {
    this.fieldProps = fieldProps;

    return (
      <React.Fragment>
        <strong>{this.props.label}</strong><br />
        <iframe
          title="Content"
          src="about:blank"
          scrolling="no"
          style={iframeStyle}
          ref={ref => this.iframeRef = ref || this.iframeRef}
        />
      </React.Fragment>
    );
  }

  render() {
    return (
      <Field
        name={this.props.source}
        render={this.renderField.bind(this)}
      />
    );
  }
}

TemplatedContentInputInner.propTypes = {
  source: PropTypes.string.isRequired,
  templateSource: PropTypes.string.isRequired,
  record: PropTypes.object.isRequired,
  form: PropTypes.object.isRequired,
};

TemplatedContentInputInner.defaultProps = {
  record: {},
};

const TemplatedContentInput = props => {
  const form = useForm();
  return <TemplatedContentInputInner {...props} form={form} />;
};

export default TemplatedContentInput;
