import * as React from 'react';
import {
  Edit,
  SimpleForm,
} from 'react-admin';
import UserGroupForm from './UserGroupForm';
import FormToolbar from '../shared/FormToolbar';

const UserGroupEdit = props => (
  <Edit {...props} undoable={false} title="Edit Group">
    <SimpleForm toolbar={<FormToolbar />}>
      <UserGroupForm {...props} />
    </SimpleForm>
  </Edit>
);

export default UserGroupEdit;
