import * as React from 'react';
import {
  Show,
  SimpleShowLayout,
  TextField,
  DateField,
  ListButton,
  useShowController,
} from 'react-admin';
import {
  get,
} from 'lodash';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import BackIcon from '@material-ui/icons/ArrowBack';

const useTableStyles = makeStyles({
  table: {
    maxWidth: 650,
  },
});

const ShareShow = props => {
  const {
    record,
  } = useShowController(props);
  const tableClasses = useTableStyles();

  return (
    <Show {...props}>
      <SimpleShowLayout>
        <TextField
          source="id"
          label="ID"
        />
        <TextField
          source="app_user_name"
          label="From"
        />
        <TextField
          source="mailto"
          label="To"
        />
        <TextField
          source="subject"
          label="Subject"
        />
        <TextField
          source="message"
          label="Message"
        />
        <DateField
          source="created_at"
          label="Sent at"
          showTime
        />
        <h3>Shared Content</h3>
        <TableContainer>
          <Table className={tableClasses.table}>
            <TableHead>
              <TableRow>
                <TableCell>Title</TableCell>
                <TableCell align="right">Visits</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {get(record, 'share_contents', []).map(content => (
                <TableRow key={content.id}>
                  <TableCell component="th" scope="row">
                    <a href={get(content, 'url', '#')} target={`share_content${get(content, 'id')}`}>
                      {get(content, 'title')}
                    </a>
                  </TableCell>
                  <TableCell align="right">{get(content, 'visitor_count', 0)}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        <ListButton
          label="Back to list"
          icon={<BackIcon />}
        />
      </SimpleShowLayout>
    </Show>
  );
};

export default ShareShow;
