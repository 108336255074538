import * as React from 'react';
import { cloneElement, useMemo } from 'react';
import PropTypes from 'prop-types';
import {
  Datagrid,
  TextField,
  DateField,
  Filter,
  SearchInput,

  useListContext,
  TopToolbar,
  sanitizeListRestProps,
} from 'react-admin';
import List from '../shared/SimappList';

const ShareActions = props => {
  const {
    className,
    exporter,
    filters,
    maxResults,
    ...rest
  } = props;
  const {
    currentSort,
    resource,
    displayedFilters,
    filterValues,
    hasCreate,
    basePath,
    selectedIds,
    showFilter,
    total,
  } = useListContext();
  return (
    <TopToolbar
      className={className}
      {...sanitizeListRestProps(rest)}
    >
      {filters && cloneElement(filters, {
        resource,
        showFilter,
        displayedFilters,
        filterValues,
        context: 'button',
      })}
    </TopToolbar>
  );
};

const ShareFilter = props => (
  <Filter {...props}>
    <SearchInput source="q" alwaysOn />
  </Filter>
);

const ShareList = props => (
  <List
    {...props}
    title="Users"
    filters={<ShareFilter />}
    actions={<ShareActions />}
    sort={{ order: 'DESC', field: 'id' }}
  >
    <Datagrid
      isRowSelectable={() => false}
      rowClick="show"
    >
      <TextField source="id" label="ID" />
      <TextField source="app_user_name" label="By User" />
      <TextField source="mailto" label="To" />
      <TextField source="subject" label="Subject" />
      <DateField
        source="created_at"
        label="Sent at"
        showTime
      />
      <TextField source="visits" />
    </Datagrid>
  </List>
);

export default ShareList;
