import * as React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import CancelIcon from '@material-ui/icons/Close';
import grey from '@material-ui/core/colors/grey';
import blue from '@material-ui/core/colors/blue';

const useStyles = makeStyles({
  outer: {
    height: 100,
    marginTop: -100
  },
  title: {
    fontSize: '0.75rem',
    marginTop: 0,
    color: grey['700']
  },
  barOuter: {
    height: 20,
    backgroundColor: grey['300'],
    padding: 5,
    borderRadius: 4,
    marginBottom: 10
  },
  barInner: {
    height: 20,
    backgroundColor: blue['600'],
    borderRadius: 4
  }
});

const ProgressBar = ({ progress, onCancel }) => {
  const classes = useStyles();
  return (
    <div className={classes.outer}>
      <p className={classes.title}>Upload Progress:</p>
      <div className={classes.barOuter}>
        <div className={classes.barInner} style={{ width: `${progress.percent}%` }} />
      </div>
      <Button
        size="small"
        startIcon={<CancelIcon />}
        onClick={onCancel}
      >
        Cancel Upload
      </Button>
    </div>
  );
};

// {progress.percent}
ProgressBar.propTypes = {
  progress: PropTypes.object,
  onCancel: PropTypes.func
};

export default ProgressBar;
