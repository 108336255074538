import React, { cloneElement, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  Datagrid,
  FunctionField,
  BooleanInput,
  SelectInput,
  Filter,
  SearchInput,
  Button,
  EditButton,
  DeleteButton,
  Confirm,
  useListContext,
  useRefresh,
  useNotify,
  TopToolbar,
  CreateButton,
  sanitizeListRestProps,
  usePermissions,
} from 'react-admin';
import { Link } from 'react-router-dom';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import BlockIcon from '@material-ui/icons/Block';
import UnblockIcon from '@material-ui/icons/Check';
import ResetIcon from '@material-ui/icons/Autorenew';
import MailIcon from '@material-ui/icons/Mail';
import ContentAdd from '@material-ui/icons/Add';
import { makeStyles } from '@material-ui/core/styles';
import hasPermission from '../shared/hasPermission';
import { sanitizeButtonProps } from '../shared/sanitizeReactProps';
import StatusTextField from '../shared/StatusTextField';
import StatusBooleanField from '../shared/StatusBooleanField';
import dataProvider from '../../dataProvider.js';
import List from '../shared/SimappList';

const API_URL = process.env.REACT_APP_API_URL;

const useStyles = makeStyles(theme => ({
  boolean: {
      marginBottom: theme.spacing(0.5),
  },
  centerBoolean: {
    display: 'block',
    textAlign: 'center',
  },
  centerBooleanTh: {
    textAlign: 'center',
  }
}));

const DistributorActions = props => {
  const {
    className,
    exporter,
    filters,
    maxResults,
    ...rest
  } = props;
  const {
    currentSort,
    resource,
    displayedFilters,
    filterValues,
    hasCreate,
    basePath,
    selectedIds,
    showFilter,
    total,
  } = useListContext();
  const { permissions } = usePermissions();

  return hasPermission(permissions, { create: ['/api/web/distributors'] })
    ? (
      <TopToolbar
        className={className}
        {...sanitizeListRestProps(rest)}
      >
        {filters && cloneElement(filters, {
          resource,
          showFilter,
          displayedFilters,
          filterValues,
          context: 'button',
        })}
        <CreateButton basePath={basePath} />
        {(hasPermission(permissions, { read: ['is-cmt-admin'] }) || hasPermission(permissions, { read: ['is-admin'] })) && (
          <InviteButton />
        )}
      </TopToolbar>
    ) : null;
};

const DistributorFilter = props => {
  const classes = useStyles();
  const { permissions } = usePermissions();
  return (
    <Filter {...props}>
      <SearchInput
        source="q"
        alwaysOn
        inputProps={{
          maxLength: 200,
        }}
      />
      {hasPermission(permissions, { read: ['is-cmt-admin'] }) && (
        <SelectInput
          source="cmt_group_id"
          choices={props.groups}
          label="Group"
          alwaysOn
        />
      )}
      {hasPermission(permissions, { read: ['is-cmt-admin'] }) && (
        <SelectInput
          source="cmt_role_id"
          choices={props.roles}
          label="Role"
          alwaysOn
        />
      )}
      {hasPermission(permissions, { read: ['is-admin'] }) && (
        <BooleanInput className={classes.boolean} label="Show only distributors" source="distributors_only" alwaysOn />
      )}
    </Filter>
  );
}

const BlockButton = props => {
  const [state, setState] = useState({
    confirmDialogIsOpen: false,
    confirmed: false
  });
  const refresh = useRefresh();
  const notify = useNotify();
  const isBlocked = !!props.record.blocked_at;
  const blockDistributor = () => fetch(`${API_URL}/distributors/${props.record.id}`, {
    credentials: 'include',
    method: 'PUT',
    mode: 'cors',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({
      blocked_at: isBlocked ? null : (new Date()).toJSON()
    })
  })
    .then(res => {
      if (res.ok) {
        refresh();
        notify(`Distributor ${isBlocked ? 'unblocked' : 'blocked'}`, 'success');
      } else {
        throw new Error('Invalid response.');
      }
    }).catch(err => {
      notify(err.toString(), 'warning');
    });
  const handleConfirm = () => {
    setState({
      confirmDialogIsOpen: false
    });
    blockDistributor();
  };
  const handleClick = () => {
    setState({
      confirmDialogIsOpen: true
    });
  };
  const handleConfirmDialogClose = () => {
    setState({
      confirmDialogIsOpen: false
    });
  };

  return props.record.local_auth !== true ? null : (
    <React.Fragment>
      <Button
        variant="outlined"
        color="primary"
        onClick={handleClick}
        title={`${isBlocked ? 'Unblock' : 'Block'} user`}
      >
        {isBlocked ? <UnblockIcon /> : <BlockIcon />}
      </Button>
      <Confirm
        isOpen={state.confirmDialogIsOpen}
        title={`${isBlocked ? 'Unblock' : 'Block'} user "${props.record.name}"`}
        content={`Do you really want to ${isBlocked ? 'unblock' : 'block'} this user?`}
        onConfirm={handleConfirm}
        onClose={handleConfirmDialogClose}
      />
    </React.Fragment>
  );
};

const ResetWrongPasswordCountButton = props => {
  const count = props.record.failed_login > 0;
  const refresh = useRefresh();
  const notify = useNotify();
  const reset = () => fetch(`${API_URL}/distributors/${props.record.id}`, {
    credentials: 'include',
    method: 'PUT',
    mode: 'cors',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({
      failed_login: 0
    })
  })
    .then(res => {
      if (res.ok) {
        refresh();
        notify('Reset failed login counter', 'success');
      } else {
        throw new Error('Invalid response.');
      }
    }).catch(err => {
      notify(err.toString(), 'warning');
    });

  return props.record.local_auth !== true ? null : (
    <React.Fragment>
      { count > 0 && (
        <Button
          variant="outlined"
          color="primary"
          onClick={reset}
          title="Reset failed login counter"
        >
          <ResetIcon />
        </Button>
      )}
    </React.Fragment>
  );
};

const ReinviteButton = props => {
  const refresh = useRefresh();
  const notify = useNotify();
  const reinviteDistributor = () => fetch(`${API_URL}/reinvite_distributor/${props.record.id}`, {
    credentials: 'include',
    mode: 'cors',
  })
    .then(res => {
      if (res.ok) {
        refresh();
        notify('Invitation resend', 'success');
      } else {
        throw new Error('Invalid response.');
      }
    }).catch(err => {
      notify(err.toString(), 'warning');
    });

  return props.record.password_set === false ? (
    <Button
      variant="outlined"
      color="primary"
      onClick={reinviteDistributor}
      title="Resend invitation"
    >
      <MailIcon />
    </Button>
  ) : null;
};

const UserDeleteButton = props => props.record.local_auth !== true ? null : (
  <DeleteButton
    {...sanitizeButtonProps(props)}
    title="Delete"
    undoable={false}
    label={null}
    confirmTitle={`Delete user "${props.record.name}"`}
    confirmContent="Are you sure to delete the user? This action cannot be undone."
  />
)

const InviteButton = props => {

  return (
    <Button
        component={Link}
        to="/distributors/invite"
        label="Invite User"
    >
        <ContentAdd />
    </Button>
  )
}

const DistributorList = props => {
  const { permissions } = usePermissions();
  const [availableRoles, setAvailableRoles] = useState([]);
  const [availableGroups, setAvailableGroups] = useState([]);
  const classes = useStyles();

  /**
   * load roles and groupson load
   */
     useEffect(() => {
      dataProvider.getList('user_roles', {pagination: {perPage: 1000}}).then(result => {
        setAvailableRoles(result.data);
      });
      dataProvider.getList('user_groups', {pagination: {perPage: 1000}, filter: {all_as_list: true }}).then(result => {
        setAvailableGroups(result.data);
      });
    }, []);

  return (
    <List
      {...props}
      filters={<DistributorFilter groups={availableGroups} roles={availableRoles} />}
      actions={<DistributorActions />}
      sort={{ order: 'DESC', field: 'id' }}
    >
      <Datagrid>
        <StatusTextField source="id" label="ID" />
        <StatusTextField source="name" label="Name" />
        <StatusTextField source="email" label="Email" />
        <StatusTextField source="mobile_phone" label="Phone" />
        {hasPermission(permissions, { read: ['is-admin'] }) && (<StatusTextField source="company" label="Company" />)}
        {hasPermission(permissions, { read: ['is-admin'] }) && (<StatusTextField source="job" label="Job" />)}
        {hasPermission(permissions, { read: ['is-cmt-admin'] }) && (<StatusTextField source="cmt_role_name" label="Role" sortable={false} />)}
        {hasPermission(permissions, { read: ['is-cmt-admin'] }) && (<StatusTextField source="cmt_group_name" label="Group" sortable={false} />)}
        {hasPermission(permissions, { read: ['is-cmt-admin'] }) && (<StatusTextField source="first_country" label="Countries" moreInTitle="countries" sortable={false} />)}
        {hasPermission(permissions, { read: ['is-cmt-admin'] }) && (<StatusBooleanField source="access_cmt" label="CMT access" looseValue={true} className={classes.centerBoolean} headerClassName={classes.centerBooleanTh} />)}
        <StatusBooleanField source="password_set" label="Password Set" className={classes.centerBoolean} headerClassName={classes.centerBooleanTh} sortable={false} />
        <FunctionField
          label="Actions"
          textAlign="right"
          render={record => (
            <ButtonGroup>
              {hasPermission(permissions, { update: ['/api/web/distributors/'] }) && !(hasPermission(permissions, { read: ['is-cmt-super-admin'] }) && record.cmt_role_id === 1)
            && (
              <EditButton
                {...sanitizeButtonProps(props)}
                title="Edit"
                label={null}
                record={record}
              />
            )}
              {hasPermission(permissions, { delete: ['/api/web/distributors/'] })
            && (
              <UserDeleteButton
                {...sanitizeButtonProps(props)}
                record={record}
              />
            )}
              {hasPermission(permissions, { update: ['/api/web/distributors/'] })
            && (
              <BlockButton
                {...sanitizeButtonProps(props)}
                record={record}
              />
            )}
              {hasPermission(permissions, { update: ['/api/web/distributors/'] })
            && (
              <ResetWrongPasswordCountButton
                {...props}
                record={record}
              />
            )}
              {hasPermission(permissions, { read: ['/api/web/reinvite_distributor/'] })
            && (
              <ReinviteButton
                {...sanitizeButtonProps(props)}
                record={record}
              />
            )}
            </ButtonGroup>
          )}
        />
      </Datagrid>
    </List>
  );
};

export default DistributorList;
