import NewsIcon from '@material-ui/icons/Announcement';
import NewsCreate from './NewsCreate';
import NewsEdit from './NewsEdit';
import NewsList from './NewsList';
import hasPermission from '../shared/hasPermission';

export default permissions => ({
  list: hasPermission(permissions, { read: ['/api/web/news'] })
    ? NewsList : null,
  create: hasPermission(permissions, { create: ['/api/web/news'] })
    ? NewsCreate : null,
  edit: hasPermission(permissions, { update: ['/api/web/news/'] })
    ? NewsEdit : null,
  icon: NewsIcon,
});
