import * as React from 'react';
import {
  TextInput,
  ReferenceInput,
  AutocompleteInput,
  ReferenceArrayInput,
  FormDataConsumer,
  required,
} from 'react-admin';
import CollapsibleArrayInput from '../shared/CollapsibleArrayInput';

const ProductForm = props => (
  <React.Fragment>
    <TextInput
      source="name"
      label="Name"
      validate={required()}
      fullWidth
    />
    <TextInput
      source="description"
      label="Description"
      fullWidth
    />
    <TextInput
      source="keyname"
      label="Keyname"
      fullWidth
    />
    <ReferenceInput
      label="Product Group"
      source="product_group_id"
      reference="product_groups"
      sort={{ field: 'name', order: 'ASC' }}
      perPage={1000}
      fullWidth
      allowEmpty={false}
      validate={required()}
    >
      <AutocompleteInput
        optionText="name"
      />
    </ReferenceInput>
    <FormDataConsumer>
      {formDataProps => (
        <ReferenceArrayInput
          resource={props.resource}
          source="product_subgroup_ids"
          reference="product_subgroups"
          sort={{ field: 'name', order: 'ASC' }}
          label="Product Sub Groups"
          filter={{ product_group_id: formDataProps.formData.product_group_id }}
          perPage={1000}
          fullWidth
        >
          <CollapsibleArrayInput
            {...formDataProps}
            source="product_subgroup_ids"
            fullWidth
          />
        </ReferenceArrayInput>
      )}
    </FormDataConsumer>
  </React.Fragment>
);

export default ProductForm;
