import ShareIcon from '@material-ui/icons/Share';
import ShareList from './ShareList';
import ShareShow from './ShareShow';
import hasPermission from '../shared/hasPermission';

export default permissions => ({
  list: hasPermission(permissions, { read: ['/api/web/sharings'] })
    ? ShareList : null,
  show: ShareShow,
  icon: ShareIcon,
});
