import ScientificLiteratureKeywordIcon from '@material-ui/icons/Label';
import ScientificLiteratureKeywordCreate from './ScientificLiteratureKeywordCreate';
import ScientificLiteratureKeywordEdit from './ScientificLiteratureKeywordEdit';
import ScientificLiteratureKeywordList from './ScientificLiteratureKeywordList';
import hasPermission from '../shared/hasPermission';

export default permissions => ({
  options: { label: 'Keywords' },
  list: hasPermission(permissions, { create: ['/api/web/scientific_literature_keywords'] })
    ? ScientificLiteratureKeywordList : null,
  create: hasPermission(permissions, { create: ['/api/web/scientific_literature_keywords'] })
    ? ScientificLiteratureKeywordCreate : null,
  edit: hasPermission(permissions, { update: ['/api/web/scientific_literature_keywords/'] })
    ? ScientificLiteratureKeywordEdit : null,
  icon: ScientificLiteratureKeywordIcon,
});
