import * as React from 'react';
import {
  Create,
  SimpleForm,
} from 'react-admin';
import DistributorForm from './DistributorForm';
import FormToolbar from '../shared/FormToolbar';

const distributorDefaultValues = {
  cmt_role_id: 4,
  invite: true
};

const DistributorInvite = props => (
  <Create {...props} basePath="/distributors" resource="distributors" title="Invite SIMAPP User">
    <SimpleForm
      toolbar={<FormToolbar />}
      initialValues={distributorDefaultValues}
      redirect="list"
    >
      <DistributorForm {...props} invite={true} record={distributorDefaultValues} />
    </SimpleForm>
  </Create>
);

export default DistributorInvite;
