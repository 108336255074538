import * as React from 'react';
import { useState } from 'react';
import classnames from 'classnames';
import { makeStyles } from '@material-ui/core/styles';
import {
  Typography,
  Divider,
  Card,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell
} from '@material-ui/core';
import {
  Title,
  SimpleForm,
  required
} from 'react-admin';
import dataProvider from '../../dataProvider.js';
import ImportFormToolbar from './ImportFormToolbar';
import FileUploadInput from '../shared/FileUploadInput';

const API_URL = process.env.REACT_APP_API_URL;

const useStyles = makeStyles(
  theme => ({
    root: {
      backgroundColor: theme.palette.background.paper,
    },
    main: {
      display: 'flex',
      'flex-direction': 'column'
    },
    noActions: {
      [theme.breakpoints.up('sm')]: {
        marginTop: '1em',
      },
    },
    processInfo: {
      'align-self': 'center',
      'font-size': '14pt',
      margin: '2em 5em'
    },
    processError: {
      'align-self': 'center',
      'font-size': '14pt',
      margin: '2em 5em',
      color: '#ee3134'
    },
    card: {
      flex: '1 1 auto',
      margin: '5px 0px'
    },
    resultCard: {
      padding: '1em 1em 1em 1em'
    },
    resultCardDivider: {
      margin: '0.5em 0em 0.5em 0em'
    },
    resultCardHeader: {
      margin: '0em 0em 0.5em 0em'
    },
    resultItem: {
      'white-space': 'pre',
      margin: '0.5em 0px'
    }
  }),
  { name: 'QcvcCreate' }
);

const renderStatistics = (resultStats, classes) => (
  <Table>
    <TableHead>
      <TableRow>
        <TableCell>Source</TableCell>
        <TableCell>Model</TableCell>
        <TableCell align="center">Created</TableCell>
        <TableCell align="center">Updated</TableCell>
        <TableCell align="center">Unchanged</TableCell>
        <TableCell align="center">Deleted</TableCell>
      </TableRow>
    </TableHead>
    <TableBody>
      {resultStats.map((row, i) => (
        <TableRow key={i}>
          <TableCell component="th" scope="row">
            {row.source}
          </TableCell>
          <TableCell component="th" scope="row">
            {row.model}
          </TableCell>
          <TableCell align="center">{row.created}</TableCell>
          <TableCell align="center">{row.updated}</TableCell>
          <TableCell align="center">{row.unchanged}</TableCell>
          <TableCell align="center">{row.deleted}</TableCell>
        </TableRow>
      ))}
    </TableBody>
  </Table>
);

const renderErrorList = (resultStats, classes) => (
  <>
    {resultStats.map((res, i) => {
      if (res.errorList.length > 0) {
        return (
          <React.Fragment key={i}>
            <Typography variant="h6">"{res.source}" -> "{res.model}"</Typography>
            <Divider className={classes.resultCardDivider} light />
            {res.errorList.map((e, i) => <p key={i} className={classes.resultItem}>{e}</p>)}
          </React.Fragment>
        );
      }
      return null;
    })}
  </>
);

export default function ImportCreate(props) {
  const {
    basePath,
    record = {},
    resource,
    title
  } = props;
  const [state, setState] = useState('prepare'); // prepare | importing | finished | error
  const [resultStats, setResultStats] = useState([]);
  const [resultErrorLists, setResultErrorLists] = useState([]);
  const [error, setError] = useState({});
  const classes = useStyles(props);
  return (
    <div className={classnames(classes.main)}>
      <Title title={title} />
      <Card className={classes.card}>
        <SimpleForm
          toolbar={<ImportFormToolbar />}
          basePath={basePath}
          record={record}
          resource={resource}
          saving={state === 'importing'}
          save={async e => {
            setState('importing');
            try {
              const { data: { data: { statistics, errorLists } } } = await dataProvider.create(resource, { data: e });
              setResultStats(statistics || []);
              setResultErrorLists(errorLists || []);
              setState('finished');
            } catch (e) {
              const error = {
                type: e.message,
                title: e.body && e.body.errors && e.body.errors.length > 0 ? e.body.errors[0].title : undefined,
                detail: e.body && e.body.errors && e.body.errors.length > 0 ? e.body.errors[0].detail : undefined,
              };
              setError(error);
              setState('error');
              console.log(e);
            }
          }}
        >
          <FileUploadInput
            source="fileInfo"
            labelSingle="simapp.action.uploadZip"
            uploadUrl={`${API_URL}/upload/dbimport`}
            validate={required()}
            accept=".zip"
          />
        </SimpleForm>
      </Card>
      { state === 'prepare'
        ? (
          <p className={classes.processInfo}> To import the data, first select a zip file. After it has been uploaded press the import button.
            While the import is running, please do not close the browser window or leave this site, it may take a while.
            After it is finished, summary statistics and identified problems will be displayed.
          </p>
        )
        : null
      }
      { state === 'importing'
        ? <p className={classes.processInfo}> Import in process, please wait ...</p>
        : null
      }
      { state === 'error'
        ? (
          <div className={classes.processError}>
            <Typography variant="h5">{error.type}</Typography>
            {error.title && error.detail
              ? (
                <p style={{ 'white-space': 'pre' }}>
                  {error.title}: <br />
                  {error.detail}
                </p>
              )
              : null
            }
          </div>
        )
        : null
      }
      { state === 'finished'
        ? (
          <>
            { resultStats.length > 0
              ? (
                <Card className={classnames(classes.card, classes.resultCard)}>
                  <Typography variant="h5">Import statistics</Typography>
                  { renderStatistics(resultStats, classes) }
                </Card>
              )
              : null
            }
            { resultErrorLists.length > 0
              ? (
                <Card className={classnames(classes.card, classes.resultCard)}>
                  <Typography variant="h5" className={classes.resultCardHeader}>Identified problems</Typography>
                  { renderErrorList(resultErrorLists, classes) }
                </Card>
              )
              : null
            }
          </>
        )
        : null
      }
    </div>
  );
}
