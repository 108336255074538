import React, { useState, useEffect } from 'react';
import {
  TextInput,
  BooleanInput,
  ReferenceArrayInput,
  SelectInput,
  CheckboxGroupInput,
  FormDataConsumer,
  required,
  email,
  minLength,
  maxLength,
  usePermissions,
} from 'react-admin';
import { useForm } from 'react-final-form';
import { makeStyles } from '@material-ui/core/styles';
import CollapsibleArrayInput from '../shared/CollapsibleArrayInput';
import GroupedArrayInput from '../shared/GroupedArrayInput';
import dataProvider from '../../dataProvider.js';
import hasPermission from '../shared/hasPermission';

const phoneValidation = (value) => {
  if (!value || !value.match(/^\+[0-9]{2,3}[0-9\- ]{1,}$/)) {
      return 'Phone number is invalid.';
  }
  return undefined;
};

const nameValidation = (value) => {
  // see https://unicode-table.com/en/#00F8
  if (!value || !value.match(/^[A-zÀ-ÖØ-öø-ÿ ]*$/)) {
      return 'Name is invalid.';
  }
  return undefined;
};

const distributorEmailValidation = (value) => {
  if (value && value.includes('@thermofisher.com') ) {
      return 'Distributors must not have a @thermofisher.com address.';
  }
  return undefined;
};

const useStyles = makeStyles({
  subgroup: {
    marginLeft: 45,
  },
  hidden: {
    display: "none"
  }
});

const GroupSelector = ({ availableGroups, multiple }) => multiple ?
(
  <CheckboxGroupInput
    source="group_ids"
    choices={availableGroups}
    label="Groups"
  />
)
:
(
  <SelectInput
    source="cmt_group_id"
    choices={availableGroups}
    label="Group"
    fullWidth
  />
)

const DistributorForm = props => {
  const [availableRoles, setAvailableRoles] = useState([]);
  const [availableGroups, setAvailableGroups] = useState([]);
  const [selectedRole, setSelectedRole] = useState(null);
  const form = useForm();
  const classes = useStyles();
  const { permissions } = usePermissions();

  /**
   * load roles on load
   */
  useEffect(() => {
    dataProvider.getList('user_roles', {pagination: {perPage: 1000}}).then(result => {
      setAvailableRoles(result.data);
      handleRoleChange(props.record.cmt_role_id, false, result.data)
    });
  }, []);

  /**
   * When a role was changed, we clear the currently selected group and get a new group list for the role
   * On load, this function is called without resetting the role (just to load the groups)
   */
  const handleRoleChange = (roleId, resetRole = true, data = null ) => {
    if(resetRole) {
      form.change('cmt_group_id', null);
      form.change('group_ids', null);
    }
    const aR = data ? data : availableRoles
    const newSelectedRole = aR.find(r => r.id === roleId);
    setSelectedRole(newSelectedRole);
    if(newSelectedRole) {
      dataProvider.getList('user_groups', { pagination: { perPage: 1000 }, filter: {master_only: newSelectedRole.permissionList.includes('cmt_read_own_group')}}).then(result => {
        setAvailableGroups(result.data);
      })
    }
  }

  return !permissions ? null : (
  <React.Fragment>
    <BooleanInput
        source="invite"
        className={classes.hidden}
      />
    { !props.invite && (
      <TextInput
        source="name"
        label="Name"
        validate={[required(), maxLength(100), nameValidation]}
        fullWidth
        helperText='Only latin characters are allowed'
      />
    )}
    <TextInput
      source="email"
      label="Email"
      validate={ (hasPermission(permissions, { read: ['is-cmt-admin'] }) || hasPermission(permissions, { read: ['is-admin'] })) ?  [required(), email()] : [required(), email(), distributorEmailValidation]}  // Non-cmt-admins may only create distris
      type="email"
      fullWidth
      disabled={!!props.id}
    />
    { !props.invite && (
      <React.Fragment>
    <TextInput
      source="mobile_phone"
      label="Phone"
      type="tel"
      validate={[required(), maxLength(100), minLength(5), phoneValidation]}
      fullWidth
      helperText='Must start with international area code, e.g. +49, followed by numbers, "-" or " "'
    />
    <TextInput
      source="job"
      label="Job Description"
      validate={[required(), maxLength(50)]}
      fullWidth
    />
    <TextInput
      source="company"
      label="Company Name"
      validate={[required(), maxLength(50)]}
      fullWidth
    />
    <FormDataConsumer>
      {/* This part
        - must be visible for distributor managers
        - must be visible if non-TMO users
      */}
      {formDataProps => (
        hasPermission(permissions, { read: ['is-content-admin-distributors'] }) ||
        hasPermission(permissions, { read: ['is-product-manager-distributors'] }) ||
        (formDataProps.formData.email && !formDataProps.formData.email.includes('@thermofisher.com'))
      ) ? (
        <ReferenceArrayInput
          resource={props.resource}
          source="country_ids"
          reference="countries"
          label="Countries"
          sort={{ field: 'name', order: 'ASC' }}
          perPage={1000}
          fullWidth
          validate={required()}
        >
          <GroupedArrayInput
            {...formDataProps}
            source="country_ids"
            groupSource="countries"
            fullWidth
          />
        </ReferenceArrayInput>
      ) : null }
    </FormDataConsumer>
    <FormDataConsumer>
      {/* This part
        - must be hidden from CMT Admins
        - must be visible for distributor managers
        - must be visible if admin AND non-TMO users
      */}
      {formDataProps => (
        hasPermission(permissions, { read: ['is-content-admin-distributors'] }) ||
        hasPermission(permissions, { read: ['is-product-manager-distributors'] }) ||
        (hasPermission(permissions, { read: ['is-admin'] }) && formDataProps.formData.email && !formDataProps.formData.email.includes('@thermofisher.com'))
      ) ? (
        <React.Fragment>
        <ReferenceArrayInput
          resource={props.resource}
          source="product_ids"
          reference="products_tree"
          label="Products"
          sort={{ field: 'name', order: 'ASC' }}
          perPage={1000}
          fullWidth
        >
          <GroupedArrayInput
            {...formDataProps}
            source="product_ids"
            groupSource="products"
            fullWidth
          />
        </ReferenceArrayInput>
        <BooleanInput
          source="access_materials"
          label="Can access materials"
          fullWidth
        />
        <BooleanInput
          source="access_software"
          label="Can access service materials"
          fullWidth
        />
        <BooleanInput
          source="access_presentations"
          label="Can create/edit presentations"
          fullWidth
        />
        <BooleanInput
          source="access_pct"
          label="Can access PCT HE Model"
          fullWidth
        />
        <BooleanInput
          source="access_copeptin"
          label="Can access Copeptin HE Model"
          fullWidth
        />
        <BooleanInput
          source="access_kryptor"
          label="Can access KRYPTOR Calculator"
          fullWidth
        />
        <BooleanInput
          source="access_qcvc"
          label="Can access QC Calculator"
          fullWidth
        />
        {formDataProps.formData.access_qcvc && (
          <div className={classes.subgroup}>
            <ReferenceArrayInput
              resource={props.resource}
              source="qcvc_country_ids"
              reference="qcvc_countries"
              label="Countries for QC Calculcator"
              sort={{ field: 'name', order: 'ASC' }}
              perPage={1000}
              fullWidth
            >
              <CollapsibleArrayInput
                {...formDataProps}
                source="qcvc_country_ids"
                optionText="name"
                fullWidth
              />
            </ReferenceArrayInput>
          </div>
        )}
        <BooleanInput
          source="access_acc"
          label="Can access Assay Competitor Comparison"
          fullWidth
        />
        </React.Fragment>
      ) : null }
      </FormDataConsumer>
      </React.Fragment>
    )}
    {(hasPermission(permissions, { read: ['is-cmt-admin'] }) || hasPermission(permissions, { read: ['is-admin'] })) && (
      <React.Fragment>
        <BooleanInput
          source="access_cmt"
          label="Can access CMT"
          fullWidth
        />
        <FormDataConsumer>
          {({ formData, ...rest }) => {
            const filteredRoles = availableRoles.filter(role => formData.email && (formData.email.includes('@thermofisher.com') || (!role.name.includes('Admin') && !role.name.includes('SuperUser'))));
            return (
            formData.access_cmt && (
              <div className={classes.subgroup}>
                <SelectInput
                  source="cmt_role_id"
                  // only TMO users can be (super) admin, because those roles need backend access
                  choices={filteredRoles}
                  label="Role"
                  fullWidth
                  onChange={e => handleRoleChange(e.target.value)}
                  disabled={props.invite}
                />
                {/* {formData.cmt_role_id && selectedRole && ( */}
                {selectedRole && (
                  <GroupSelector availableGroups={availableGroups} multiple={selectedRole.permissionList.includes('cmt_read_all_groups')} />
                )}
                <BooleanInput
                  source="cmt_trained_kryptor"
                  label="Trained Kryptor/Chat LIS"
                  fullWidth
                />
                <BooleanInput
                  source="cmt_trained_fastscreen"
                  label="Trained Fast Screen PLUS"
                  fullWidth
                />
              </div>
            )
          )}}
        </FormDataConsumer>
      </React.Fragment>
    )}
  </React.Fragment>
)};

export default DistributorForm;
