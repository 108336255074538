import ConsumablesCalculatorMultiControlIcon from '@material-ui/icons/Widgets';
import ConsumablesCalculatorMultiControlCreate from './ConsumablesCalculatorMultiControlCreate';
import ConsumablesCalculatorMultiControlEdit from './ConsumablesCalculatorMultiControlEdit';
import ConsumablesCalculatorMultiControlList from './ConsumablesCalculatorMultiControlList';
import hasPermission from '../shared/hasPermission';

export default permissions => ({
  options: { label: 'CC Multi Control' },
  list: hasPermission(permissions, { read: ['/api/web/consumables_calculator_multi_controls'] })
    ? ConsumablesCalculatorMultiControlList : null,
  create: hasPermission(permissions, { create: ['/api/web/consumables_calculator_multi_controls'] })
    ? ConsumablesCalculatorMultiControlCreate : null,
  edit: hasPermission(permissions, { update: ['/api/web/consumables_calculator_multi_controls/'] })
    ? ConsumablesCalculatorMultiControlEdit : null,
  icon: ConsumablesCalculatorMultiControlIcon,
});
