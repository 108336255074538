import * as React from 'react';
import { useSelector } from 'react-redux';
import { useMediaQuery } from '@material-ui/core';
import { MenuItemLink, getResources } from 'react-admin';
import DefaultIcon from '@material-ui/icons/ViewList';
import inflection from 'inflection';
import { useTranslate } from 'ra-core';

const translatedResourceName = (resource, translate) => translate(`resources.${resource.name}.name`, {
  smart_count: 2,
  _:
      resource.options && resource.options.label
        ? translate(resource.options.label, {
          smart_count: 2,
          _: resource.options.label,
        })
        : inflection.humanize(inflection.pluralize(resource.name)),
});

const Menu = ({ onMenuClick, logout }) => {
  const translate = useTranslate();
  const isXSmall = useMediaQuery(theme => theme.breakpoints.down('xs'));
  const open = useSelector(state => state.admin.ui.sidebarOpen);
  const resources = useSelector(getResources);
  return (
    <div>
      {resources
        .map(resource => {
          if (resource.hasList || (/* resource.hasCreate && */ resource.options.showInMenu)) {
            return (
              <MenuItemLink
                key={resource.name}
                to={resource.hasList ? `/${resource.name}` : `/${resource.name}/create`}
                primaryText={translatedResourceName(
                  resource,
                  translate
                )}
                leftIcon={
                  resource.icon ? <resource.icon /> : <DefaultIcon />
                }
                onClick={onMenuClick}
                sidebarIsOpen={open}
              />
            );
          }
          return null;
        })}
      {isXSmall && logout}
    </div>
  );
};

export default Menu;
