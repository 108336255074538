import * as React from 'react';
import {
  TextInput,
  ArrayInput,
  ReferenceArrayInput,
  ReferenceInput,
  BooleanInput,
  AutocompleteInput,
  FormDataConsumer,
  SimpleFormIterator,
  required,
} from 'react-admin';
import { useForm } from 'react-final-form';
import GroupedArrayInput from '../shared/GroupedArrayInput';
import CollapsibleArrayInput from '../shared/CollapsibleArrayInput';
import TemplatedContentInput from '../shared/TemplatedContentInput';

const ContentPieceForm = props => (
  <React.Fragment>
    <ReferenceInput
      source="template_id"
      label="Template"
      reference="content_templates"
      perPage={1000}
      fullWidth
      allowEmpty={false}
      validate={required()}
    >
      <AutocompleteInput
        optionText="name"
      />
    </ReferenceInput>

    <FormDataConsumer>
      {formDataProps => (
        <TemplatedContentInput
          {...formDataProps}
          source="content"
          templateSource="template_id"
          label="Edit Content"
          fullWidth
          record={props.record}
        />
      )}
    </FormDataConsumer>

    <ReferenceInput
      source="content_type_id"
      label="Content Type"
      reference="content_types"
      perPage={1000}
      fullWidth
      allowEmpty={false}
      validate={required()}
    >
      <AutocompleteInput
        optionText="name"
      />
    </ReferenceInput>
    <TextInput
      source="title"
      validate={required()}
      fullWidth
    />
    <TextInput
      source="mastercontrol_id"
      label="Master Control ID"
      helperText="Reference ID from mastercontrol"
      validate={required()}
      fullWidth
    />
    <ReferenceInput
      source="language_id"
      reference="languages"
      sort={{ field: 'name', order: 'ASC' }}
      label="Language"
      perPage={1000}
      allowEmpty={false}
      validate={required()}
      fullWidth
    >
      <AutocompleteInput
        optionText="name"
        fullWidth
      />
    </ReferenceInput>
    <ReferenceArrayInput
      resource={props.resource}
      source="country_ids"
      reference="countries"
      sort={{ field: 'name', order: 'ASC' }}
      label="Available in country"
      perPage={1000}
      fullWidth
      validate={required()}
    >
      <FormDataConsumer>
        {formDataProps => (
          <GroupedArrayInput
            {...formDataProps}
            source="country_ids"
            groupSource="countries"
            fullWidth
          />
        )}
      </FormDataConsumer>
    </ReferenceArrayInput>

    <ReferenceArrayInput
      resource={props.resource}
      source="topic_ids"
      reference="topics"
      label="Topic"
      perPage={1000}
      fullWidth
    >
      <FormDataConsumer>
        {formDataProps => (
          <CollapsibleArrayInput
            {...formDataProps}
            source="topic_ids"
            optionText="title"
            fullWidth
          />
        )}
      </FormDataConsumer>
    </ReferenceArrayInput>

    <ReferenceArrayInput
      resource={props.resource}
      source="characteristic_ids"
      reference="characteristics"
      label="Characteristics"
      perPage={1000}
      fullWidth
      validate={required()}
    >
      <FormDataConsumer>
        {formDataProps => (
          <CollapsibleArrayInput
            {...formDataProps}
            source="characteristic_ids"
            optionText="name"
            fullWidth
          />
        )}
      </FormDataConsumer>
    </ReferenceArrayInput>

    <ReferenceArrayInput
      resource={props.resource}
      source="speciality_ids"
      reference="specialities"
      label="Speciality"
      perPage={1000}
      fullWidth
      validate={required()}
    >
      <FormDataConsumer>
        {formDataProps => (
          <CollapsibleArrayInput
            {...formDataProps}
            source="speciality_ids"
            optionText="name"
            fullWidth
          />
        )}
      </FormDataConsumer>
    </ReferenceArrayInput>

    <TextInput
      source="description"
      label="Description"
      validate={required()}
      fullWidth
      multiline
    />

    <TextInput
      source="usage"
      label="Usage"
      fullWidth
      multiline
    />
    <TextInput
      source="comment"
      label="Internal Comment"
      fullWidth
      multiline
    />

    <ReferenceArrayInput
      resource={props.resource}
      source="required_pieces"
      reference="content_pieces"
      label="Required Content Pieces"
      perPage={1000}
      fullWidth
    >
      <FormDataConsumer>
        {formDataProps => (
          <CollapsibleArrayInput
            {...formDataProps}
            source="required_pieces"
            optionText="title"
            fullWidth
          />
        )}
      </FormDataConsumer>
    </ReferenceArrayInput>

    <ReferenceArrayInput
      resource={props.resource}
      source="excluded_pieces"
      reference="content_pieces"
      label="Excluded Content Pieces"
      perPage={1000}
      fullWidth
    >
      <FormDataConsumer>
        {formDataProps => (
          <CollapsibleArrayInput
            {...formDataProps}
            source="excluded_pieces"
            optionText="title"
            fullWidth
          />
        )}
      </FormDataConsumer>
    </ReferenceArrayInput>

    <ArrayInput
      source="refrences"
      label="References"
      fullWidth
    >
      <SimpleFormIterator>
        <TextInput source="key" label="Key" />
        <TextInput source="value" label="Reference" fullWidth />
      </SimpleFormIterator>
    </ArrayInput>

    <BooleanInput
      source="active"
      label="Make this content Active"
    />
    <BooleanInput
      source="shareable"
      label="Make this content Share Enabled"
    />
    <BooleanInput
      source="available_for_distributors"
      label="Make this content available for Distributors"
    />
  </React.Fragment>
);

export default ContentPieceForm;
