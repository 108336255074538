export default [
  {
    id: 'admin',
    name: 'Admin'
  },
  {
    id: 'cmt-admin',
    name: 'CMT Admin',
  },
  {
    id: 'cmt-super-admin',
    name: 'CMT SuperAdmin',
  },
  {
    id: 'content-admin',
    name: 'Content Admin',
  },
  {
    id: 'content-admin-distributors',
    name: 'Content Admin Distributors',
  },
  {
    id: 'product-manager',
    name: 'Product Manager',
  },
  {
    id: 'product-manager-distributors',
    name: 'Product Manager Distributors',
  },
  {
    id: 'material-manager',
    name: 'Material Manager',
  },
  {
    id: 'qcvc-manager',
    name: 'QCVC Manager',
  },
];
